<template>
  <el-dialog
    title="联系收件员"
    :visible.sync="dialogVisible5"
    width="200"
    :before-close="handleClose"
    center
  >
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      "
    >
      <span sytle="margin-right:10px;">电话</span> {{ empPhone }}
    </div>

    <span slot="footer" class="dialog-footer">
      <div class="submit submit-btn" @click="close()">确定</div>
    </span>
  </el-dialog>
</template>
<script>
import OSS from "ali-oss";
import newsite from "@/views/newtangc/newsite";
import { async } from "q";

export default {
  components: {
    newsite,
  },
  created() {
    this.getSFById();
    this.getSjdz();
    this.getTable();
    this.dizhijieko();
    this.timeFormat();

    this.radio = "1";
    this.baseURL = this.$http.defaults.baseURL + "/caseInfo/stepEight";
  },
  activated() {
    this.getSjdz();
    this.getSFById();
    this.radio = "1";
    this.baseURL = this.$http.defaults.baseURL + "/caseInfo/stepEight";
  },
  props: {
    dialogVisible5:{
       type:Boolean
    },
   
    phaseid: {
      type: Number,
    },
    token: {
      type: Object,
    },
    erpcaseno: {
      type: String,
    },
  },
  data() {
  
    return {
      selectActive: false,
      strName: [],
      dizAddress: "",
      isShow: false,
      str: [],
      yjmx: [],
      empPhone:'',
      takeNo: "",
      isEdit: false,
    
      //寄件信息
      jjxl: {
        jjdz: {},
        sjdz: {},
        yyqj: "",
      },
      nowH: "",
      isOpen: true,
      //寄件信息
      caseNo: "",
      caseNoId: "", //寄件单号
      ht: { id: 3, checked: false },
      mt: { id: 2, checked: false },
      jt: { id: 1, checked: false },
      qjsj: {}, //取件时间
      diz: "",
      hoursList: [],
      dizshuzu: [],
      address: {
        province: "天津市",
        city: "天津市",
        area: "东丽区空港经济区",
        address: "中环西路56号中科天保智谷9号楼正丽科技 ",
        deliveryName: "正丽科技 ",
        phone: "15339368485",
      },
      origin: "", //
      selectedArrData: [], // 把选择到的当前行的id存到数组中
      imgEidt: require("@/assets/address_edit.png"),
      imgCopy: require("@/assets/copy.png"),
      imgUrl: require("@/assets/check-circle.png"),
      search: "",
      dialogVisible2: false,
      dialogVisible: false,
      dialogVisible3: false,
      dialogVisible4: false, //邮寄地址选择
      isQjsj: false,
      radio: 1, //上传类型
      upType: 1, //上传方式
      mailType: 0, //邮寄方式
      tableData: [],
      tableData2: [
        {
          id: 1,
          upTime: "2022.08.12",
          name: "李尔",
          urlId: "magicalign.com",
          checked: false,
        },
        {
          id: 2,
          upTime: "2022.08.12",
          name: "李尔2",
          urlId: "magicalign.com",
          checked: false,
        },
      ],
      uplist: [],
      newImg: require("@/assets/2.2病例详情/newfile.png"),
      fileImg: require("@/assets/2.2病例详情/file.png"),
      // 是否是新建,true不是,false是

      isNew: true,
      fileList: [],
      // 是否上传成功
      flag: true,
      OK: require("@/assets/3新建病例/上传文件.png"), //已上传
      NO: require("@/assets/3新建病例/PC-新建病例上传文件.png"), //未上传
      type: "",
      scwjadd: "Y",
      baseURL: "", // 上传文件路径默认地址
      newArr: {},
    };
  },
  methods: {
     close(){
        this.$emit('dialogVisible5')
     },
    stringStr(dizAddress) {
      let str = "";
      if (dizAddress && dizAddress.length > 53) {
        str = dizAddress.substr(0, 53) + "...";
      } else {
        str = dizAddress;
      }
      return str;
    },
    upChange() {
      if (this.radio == 2) {
        this.getSFById();
      }
    },
    selectChange() {
      this.selectActive = false;
      this.dizshuzu.filter((item) => {
        console.log(item, this.diz);
        if (item.id == this.diz) {
          this.dizAddress = item.name;
        }
      });
    },
    clickSelect() {
      if (this.isEdit) {
        return;
      }
      if (!this.selectActive) {
        this.$refs.myselect.$el.click();
        this.selectActive = true;
      }
    },
    getScan() {
      this.$http({
        url: "/caseInfo/getStepEightScan",
        method: "get",
        params: {
          caseid: this.$store.state.caseId + "",
          stageName: this.$store.state.stageNameId,
          // 病例次数
          stageCount: this.$store.state.stageCountId,
        },
      })
        .then(({ data }) => {
          data = JSON.parse(data.data);

          this.fileList = [];
          // console.log(data);
          data.forEach((item) => {
            console.log(item);
            if (item.filenumber == "12") {
              // console.log(item);
              this.fileList.push({
                name: item.filename,
                url: item.ossfilepath,
                id: item.casedataid,
              });
              this.flag = false;
              this.scwjadd = "N";
            }
          });
        })
        .catch((err) => {});
    },

    yjmxChange(val) {
      if (this.yjmx.indexOf(val) != -1) {
        this.yjmx.splice(this.yjmx.indexOf(val), 1);
      } else {
        this.yjmx.push(val);
      }
      if (this.yjmx.length == 0) {
        this.mailType = 0;
      }
    },
    setupFileList() {
      this.$emit("setupFileList", this.fileList);
    },
    lxjsjy() {
      
    },
    getSFById(isflg) {
      this.$set(this.jjxl.jjdz, "address", "");
      this.$set(this.jjxl.jjdz, "contactNumber", "");
      this.$set(this.jjxl.jjdz, "deliveryName", "");
      this.$set(this.jjxl, "yyqj", "");

      this.yjmx = [];
      this.edit = 1;
      this.cancel = 1;
      this.state = "";

      let data = { caseIds: this.$store.state.caseId, mailingMethod: this.mailType }; //病例id

      this.$http({
        url: "/webCase/opGetSFById",
        method: "post",
        dataType: "json",
        data: data,
        contentType: "application/x-www-form-urlencoded",
      })
        .then(({ data }) => {
          this.isOpen = true;
          this.LoadingClose();
          if (data.code == 200) {
            if (isflg) {
              this.dialogVisible3 = false;
              this.$message({
                message: "下单成功",
                type: "success",
                duration: 1500,
              });
            }

            let res = JSON.parse(data.data);
            console.log(res);
            if (res != null) {
              this.state = res.state;
              this.edit = res.edit;
              this.cancel = res.cancel;

              this.caseNoId = res.orderNo;
              let sj = "";
              /*  * 寄件地址：
                         中国上海上海闵行万金中心10F
    
                         海波13641636968
    
                         收件地址：
                         中环西路86号中科天保智谷9号楼
    
                         正丽科技质检部15890128745
    
                             * 预约取件：
                         今天(2022.12.21) 18: 00~19: 00 */

              if (res.mailingModel == 1) {
                this.yjmx = [1];
              } else if (res.mailingModel == 3) {
                this.yjmx = [1, 2];
              } else if (res.mailingModel == 2) {
                this.yjmx = [2];
              }
              console.log(this.yjmx);
              if (res.mailingMethod) {
                this.mailType = Number(res.mailingMethod);
              }
              if (res.orderState != "1") {
                let time = new Date();

                time = new Date(res.bookingTime);
                let time2 = new Date(time.getTime() + 1000 * 60 * 60);
                let jt = new Date();
                let Y = jt.getFullYear();
                let M = jt.getMonth() + 1;
                let D = jt.getDate();

                let mt = new Date(
                  new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                    24 * 3600 * 1000
                );
                let ht = new Date(
                  new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                    48 * 3600 * 1000
                );
                let rq = "";
                if (
                  time.getDate() == new Date().getDate() &&
                  time.getMonth() == new Date().getMonth()
                ) {
                  rq = "今天";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 1000 * 60 * 60);
                  } else {
                    time2 = new Date(time.getTime() + 1000 * 60 * 30);
                  }
                  sj =
                    `(${rq}) ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                    ${
                      this.addZero(time.getHours()) +
                      ":" +
                      time.getMinutes() +
                      ":" +
                      this.addZero(time.getSeconds())
                    } ~ 
                    ${
                      this.addZero(time2.getHours()) +
                      ":" +
                      time2.getMinutes() +
                      ":" +
                      this.addZero(time2.getSeconds())
                    } `;
                } else if (time.getTime() < ht.getTime()) {
                  rq = "明天";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 25 * 1000 * 60 * 60);
                  } else {
                    time2 = new Date(
                      time.getTime() + 24 * 1000 * 60 * 60 + 60 * 1000 * 30
                    );
                  }
                  sj =
                    `(${rq}) ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                    ${
                      this.addZero(time.getHours()) +
                      ":" +
                      time.getMinutes() +
                      ":" +
                      this.addZero(time.getSeconds())
                    } ~ 
                    ${
                      this.addZero(time2.getHours()) +
                      ":" +
                      time2.getMinutes() +
                      ":" +
                      this.addZero(time2.getSeconds())
                    } `;
                } else {
                  rq = "后天";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 49 * 1000 * 60 * 60);
                  } else {
                    time2 = new Date(
                      time.getTime() + 48 * 1000 * 60 * 60 + 60 * 1000 * 30
                    );
                  }

                  sj =
                    `(${rq}) ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                    ${
                      this.addZero(time.getHours()) +
                      ":" +
                      time.getMinutes() +
                      ":" +
                      this.addZero(time.getSeconds())
                    } ~ 
                    ${
                      this.addZero(time2.getHours()) +
                      ":" +
                      time2.getMinutes() +
                      ":" +
                      this.addZero(time2.getSeconds())
                    } `;
                }

                // }
                // this.$set( this.jjxl,'yyqj',  '')
                this.empPhone = res.empPhone;
                console.log( res.empPhone);
                this.takeNo = res.takeNo;
                //   console.log(this.dizshuzu);
                this.dizshuzu.filter((item) => {
                  console.log(item.id, res.addressId);
                  if (
                    item.id == res.addressId ||
                    item.id == res.sendAddressIdWeb
                  ) {
                    
                    this.empPhone?"":this.empPhone=item.contactNumber
           
                    this.$set(
                      this.jjxl.jjdz,
                      "address",
                      item.country + item.province + item.city + item.area + item.address
                    );

                    this.$set(this.jjxl.jjdz, "contactNumber", item.contactNumber);
                    this.$set(this.jjxl.jjdz, "deliveryName", item.deliveryName);
                    this.$set(this.jjxl, "yyqj", sj);
                  }
                });
              }
            }
          }
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
    xgdj() {
      this.isEdit = true;
      this.dialogVisible3 = true;
    },
    //收件地址
    getSjdz() {
      this.$http({
        url: "/webCase/optoAddress",
        method: "post",
      })
        .then(({ data }) => {
          this.isOpen = true;

          if (data.code == 200) {
            let res = JSON.parse(data.data);
            // console.log(res);
            this.jjxl.sjdz.address = res.address;
            this.jjxl.sjdz.city = res.city;
            this.jjxl.sjdz.province = res.province;
            this.jjxl.sjdz.area = res.county;
            this.jjxl.sjdz.contactNumber = res.tel;
            this.jjxl.sjdz.deliveryName = res.contact;
            // console.log(this.jjxl.sjdz);
          }
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
    qxjj() {
      if (this.isOpen) {
        this.isOpen = false;
        this.$confirm("你是否要取消寄件", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http({
              url: "/webCase/opCancelOrder",
              method: "post",
              data: {
                dealType: 2,
                language: "zh_CN",
                orderId: this.caseNoId,
              },
            })
              .then(({ data }) => {
                this.isOpen = true;

                if (data.code == 200) {
                  this.getSFById();
                  this.$message({
                    message: "取消寄件成功",
                    type: "success",
                    duration: 1500,
                  });
                }
              })
              .catch((err) => {
                this.isOpen = true;

                console.log(err);
              });
          })
          .catch((err) => {
            this.isOpen = true;
            return;
          });
      }
    },

    checkHours(val) {
      console.log(val);
      this.hoursList = this.hoursList.filter((item) => {
        if (val.id == item.id) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return true;
      });
      this.qjsj.txt =
        val.txt +
        " (" +
        val.time +
        ") " +
        (val.id == 0 ? "一小时内" : val.start + "~" + val.end);
      this.qjsj.dayId = val.dayId;
      this.qjsj.hoursId = val.id;
      val.id == 0
        ? (this.qjsj.time =
            val.formatTime +
            ` ${
              new Date().getHours() < 10
                ? "0" + new Date().getHours()
                : new Date().getHours()
            }:` +
            `${
              new Date().getMinutes() < 10
                ? "0" + new Date().getMinutes()
                : new Date().getMinutes()
            }:` +
            `${
              new Date().getSeconds() < 10
                ? "0" + new Date().getSeconds()
                : new Date().getSeconds()
            }`)
        : (this.qjsj.time = val.formatTime + " " + val.start + ":00");

      this.isQjsj = false;
    },
    checkDay(val) {
      this.jt.checked = false;
      this.mt.checked = false;
      this.ht.checked = false;
      val.checked = true;

      this.hoursList = [];
      if (val.h) {
        var h = new Date().getHours();
        this.nowH = h;
        h < 8 ? (h = 8) : h;
        var m = new Date().getMinutes();
        if (h > 20 || (h == 20 && m > 30)) {
          return;
        }
        console.log(val);
        this.hoursList.push({
          dayId: val.id,
          id: 0,
          txt: val.txt,
          time: val.time,
          formatTime: val.formatTime,
          checked: false,
        });
        for (var i = h; i < 20; i++) {
          this.hoursList.push({
            dayId: val.id,
            id: i,
            time: val.time,
            formatTime: val.formatTime,
            txt: val.txt,
            checked: false,
            start: (i < 10 ? "0" + i : i) + ":00",
            end: (i + 1 < 10 ? "0" + (i + 1) : i + 1) + ":00",
          });
        }
        this.hoursList.push({
          dayId: val.id,
          id: i,
          time: val.time,
          formatTime: val.formatTime,
          txt: val.txt,
          checked: false,
          start: (i < 10 ? "0" + i : i) + ":00",
          end: "20:30",
        });
      } else {
        for (var i = 8; i < 20; i++) {
          this.hoursList.push({
            dayId: val.id,
            id: i,
            time: val.time,
            formatTime: val.formatTime,
            txt: val.txt,
            checked: false,
            start: (i < 10 ? "0" + i : i) + ":00",
            end: (i + 1 < 10 ? "0" + (i + 1) : i + 1) + ":00",
          });
        }
        this.hoursList.push({
          dayId: val.id,
          id: i,
          time: val.time,
          formatTime: val.formatTime,
          txt: val.txt,
          checked: false,
          start: (i < 10 ? "0" + i : i) + ":00",
          end: "20:30",
        });
      }
    },
    timeFormat() {
      let time = new Date();
      let y = time.getFullYear();
      let m = this.addZero(time.getMonth() + 1);
      let d = this.addZero(time.getDate());
      let h = time.getHours();
      this.jt.txt = "今天";
      this.jt.time = `${y}.${m}.${d}`;
      this.jt.formatTime = `${y}-${m}-${d}`;
      this.jt.h = h;
      time = new Date(new Date().getTime() + 3600000 * 24);
      y = time.getFullYear();
      m = this.addZero(time.getMonth() + 1);
      d = this.addZero(time.getDate());
      h = time.getHours();
      this.mt.txt = "明天";
      this.mt.time = ` ${y}.${m}.${d}`;
      this.mt.formatTime = `${y}-${m}-${d}`;
      time = new Date(new Date().getTime() + 3600000 * 24 * 2);
      y = time.getFullYear();
      m = this.addZero(time.getMonth() + 1);
      d = this.addZero(time.getDate());
      h = time.getHours();
      this.ht.txt = "后天";

      this.ht.time = ` ${y}.${m}.${d}`;
      this.ht.formatTime = `${y}-${m}-${d}`;
    },
    addZero(val) {
      if (val < 10) {
        return "0" + val;
      } else {
        return val;
      }
    },
    copyAddress() {
      let copyInput = document.createElement("input"); //创建input元素
      document.body.appendChild(copyInput); //向页面底部追加输入框
      copyInput.setAttribute(
        "value",
        this.jjxl.sjdz.province +
          this.jjxl.sjdz.city +
          this.jjxl.sjdz.area +
          this.jjxl.sjdz.address +
          this.jjxl.sjdz.deliveryName +
          this.jjxl.sjdz.contactNumber
      ); //添加属性，将url赋值给input元素的value属性
      copyInput.select(); //选择input元素
      document.execCommand("Copy"); //执行复制命令
      this.$message.success("地址复制成功"); //弹出提示信息，不同组件可能存在写法不同
      //复制之后再删除元素，否则无法成功赋值
      copyInput.remove(); //删除动态创建的节点
    },
    eidtAddres() {
      this.$refs.tanchuangdizhi.dialogFormVisibledizhi = true;
    },
    // 新增地址方法
    pushdizhishuju() {
      this.dizhijieko();
      // this.form.diz =
      // this.$refs.tanchuangdizhi.country +
      // this.$refs.tanchuangdizhi.province +
      // this.$refs.tanchuangdizhi.city +
      // this.$refs.tanchuangdizhi.area +
      // this.$refs.tanchuangdizhi.form.diz;
      // this.dizshuzu.push(this.form.diz)
    },
    // 收货收获地址列表
    dizhijieko() {
        this.$http({
          url: "/deliveryAddress/getAddressList",
          method: "get",
          params: {},
        })
          .then(({ data }) => {
            if (data.code == 200) {
              this.dizshuzu = JSON.parse(data.data).map((x) => {
                return {
                  name:
                    x.country +
                    x.province +
                    x.city +
                    x.area +
                    x.address +
                    " - " +
                    x.deliveryName,
                  id: x.id.toString(),
                  country: x.country,
                  province: x.province,
                  city: x.city,
                  area: x.area,
                  address: x.address,
                  deliveryName: x.deliveryName,
                  contactNumber: x.contactNumber,
                  orthoPlusId: x.orthoPlusId,
                  del: x.del,
                };
              });
                if(this.diz){
                     this.dizshuzu.filter(item=>{
                        if(item.id==this.diz){
                            this.dizAddress=item.name
                        }
                     })
                }
              ////   console.log(this.dizshuzu);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    upFile() {
      this.dialogVisible = true;
    },
    submit(val, isEdit) {
      if (val == 1) {
        this.dialogVisible = false;
        this.dialogVisible2 = true;
      } else if (val == 3 && !isEdit) {
        this.orders();
      } else if (val == 3 && isEdit) {
        this.upOrder();
      } else {
        this.dialogVisible2 = false;
      }
    },
    upOrder() {
      //    console.log(this.qjsj);
      let time = this.qjsj.time.split(" ");

      let params = {
        cancel: false,
        deliverDate: time[0],
        deliverTimeMin: time[1],
        waybillNo: this.takeNo,
        serviceCode: 7,
      };

      this.$http({
        url: "/webCase/EditOrders",
        method: "POST",
        data: params,
      })
        .then(({ data }) => {
          this.$message({ message: "修改成功", type: "success", duration: 1500 });
          this.dialogVisible3 = false;

          this.getSFById();
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
    //下单
    orders(val) {
      let params = {};
      val == 2 ? (this.mailType = 2) : "";
      if (!this.diz && val != 2) {
        this.$message({ message: "请填写寄件地址", type: "error", duration: 1500 });
        return;
      }
      if (!this.qjsj.txt && val != 2) {
        this.$message({ message: "请选择预约取件时间", type: "error", duration: 1500 });
        return;
      }
      if (this.isOpen) {
        this.isOpen = false;
        let address = this.dizshuzu.filter((item) => {
          if (item.id == this.diz) {
            return item;
          }
        })[0];

        let time = new Date();
        let mailingModel = 0;
        this.yjmx.filter((item) => {
          mailingModel += item;
        });
        if (val != 2) {
          params = {
            caseId: this.$store.state.caseId, //病例id
            caseNo: this.erpcaseno, //病例编号
            sendAddressIdWeb: address.id, //地址id
            // recv_address:address.address,//收件人地址
            sendAddressIdOrtho: address.orthoPlusId, // orthID
            mailingModel: mailingModel,
            mailingMethod: Number(this.mailType),

            orderId:
              this.erpcaseno +
              time.getFullYear() +
              (time.getMonth() + 1) +
              time.getDate() +
              time.getHours() +
              time.getMinutes() +
              time.getSeconds(), //病例编号
            phone: address.contactNumber,
            contactInfoList: [
              {
                address: address.address,
                city: address.city,
                county: address.area,
                province: address.province,

                contact: address.deliveryName,
                contactType: 1,
                country: "CN",
                tel: address.contactNumber,
              },
            ],
            expressTypeId: 2, //标准快速
            sendStartTm: this.qjsj.time, // 取件时间
          };
        } else {
          if (this.caseNoId) {
            this.$http({
              url: "/webCase/opCancelOrder",
              method: "post",
              data: {
                dealType: 2,
                language: "zh_CN",
                orderId: this.caseNoId,
              },
            })
              .then(({ data }) => {
                if (data.code == 200) {
                  this.LoadingClose();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          params = {
            caseId: this.$store.state.caseId, //病例id
            caseNo: this.erpcaseno, //病例编号
            mailingModel: mailingModel,
            mailingMethod: 2,
          };
        }
        this.$http({
          url: "/webCase/opCallSFOrder",
          method: "POST",
          data: params,
        })
          .then(({ data }) => {
            this.isOpen = true;

            this.jjxl.yyqj = this.qjsj.txt;
            this.caseNoId = params.orderId;
            this.LoadingOpen();
            if (val != 2) {
              setTimeout(() => {
                this.getSFById(true);
              }, 3000);
            } else {
              this.getSFById();
            }
          })
          .catch((err) => {
            this.isOpen = true;

            console.log(err);
          });
      }
    },
    canel(val) {
      val == 1 ? (this.dialogVisible = false) : (this.dialogVisible2 = false);
      switch (val) {
        case 1:
          this.dialogVisible = false;
          break;
        case 2:
          this.dialogVisible2 = false;
          break;
        case 3:
          this.dialogVisible3 = false;
          this.isEdit = false;
          break;
      }
    },
    // 某一行被点击行触发事件，默认形参代表一整行数据
    rowClickEv(row) {
      console.log(row); //点击的那行数据默认是对象{__ob__: Observer}，将其转数组
      row.checked = !row.checked;
      if (row.checked) {
        this.selectedArrData.push(row);
      } else {
        this.selectedArrData = this.selectedArrData.filter((item) => {
          if (item.id != row.id) {
            return true;
          }
        });
      }
    },

    isRed({ row }) {
      const checkIdList = this.selectedArrData.map((item) => item.id);
      if (checkIdList.includes(row.id)) {
        return {
          backgroundColor: "#1275D2",
          color: "#FFFFFF",
        };
      }
    },
    handleCurrentChange(val) {
      this.tableData2 = this.tableData2.filter((item) => {
        if (val.id == item.id) {
          item.checked = !item.checked;
        }
        return true;
      });
      console.log(this.tableData2);
    },
    bindId() {},
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
        this.$emit('dialogVisible5')
           
          done();
        })
        .catch(() => {});
    },
    delFn(id) {
      console.log(id);
      this.tableData = this.tableData.filter((item) => {
        if (item.id == id) {
          return false;
        } else {
          return true;
        }
      });
    },
    getTable() {
      this.tableData = [
        {
          id: 1,
          upTime: "2022.08.12",
          name: "李尔",
          urlId: "magicalign.com",
        },
        {
          id: 2,
          upTime: "2022.08.12",
          name: "李尔2",
          urlId: "magicalign.com",
        },
      ];
    },
    // =======================\\
    schuawj() {
      this.$refs.schuanwj.submit();
    },
    tihuan() {
      console.log(this.str);
      for (let i = 0; i < this.str.length; i++) {
        if (this.str[i].name) {
          for (var j = 0; j < this.fileList.length; j++) {
            if (this.fileList[j].name == this.str[i].name) {
              this.fileList.splice(j, 1);
              break;
            }
          }
          for (var j = 0; j < this.fileList.length; j++) {
            if (this.fileList[j].name == this.str[i].name) {
              this.fileList[j].casedataid = this.str[i].casedataid;
              this.fileList[j].update = true;
              break;
            }
          }
        }
      }
      this.isShow = false;
    },
    baoliu() {
      let obj = {};

      for (let j = 0; j < this.fileList.length; j++) {
        let gs = this.fileList[j].name.substr(
          this.fileList[j].name.lastIndexOf("."),
          this.fileList[j].name.length
        );
        let name = this.fileList[j].name.substr(
          0,
          this.fileList[j].name.lastIndexOf(".")
        );

        for (let i = 0; i < this.fileList.length; i++) {
          let fileGs = this.fileList[i].name.substr(
            this.fileList[i].name.lastIndexOf("."),
            this.fileList[i].name.length
          );
          let fileName = "";
          if (gs == fileGs) {
            if (this.fileList[i].name.indexOf(/([0-9])/) != -1) {
              fileName = this.fileList[i].name.substr(
                0,
                this.fileList[i].name.lastIndexOf(/([0-9])/)
              );
            } else {
              fileName = this.fileList[i].name.substr(
                0,
                this.fileList[i].name.lastIndexOf(".")
              );
            }

            if (fileName == name) {
              console.log(obj, obj[name], name);
              if (obj[name] != undefined) {
                this.fileList[i].name = fileName + "(" + obj[name]++ + ")" + gs;
              } else {
                obj[name] = 1;
              }
            }
          }
        }
      }

      this.isShow = false;
    },
    // 判断文件格式
    ifType(file, fileList) {
      const fileExt = file.name.substr(file.name.lastIndexOf(".")).toLowerCase();

      const isLt2M = file.raw.size / 1024 / 1024 < 100;

      if (!isLt2M) {
        this.$message.error("上传文件最大100MB !");
        return false;
      }

      this.str = [];
      this.strName = [];
      for (let i = 0; i < fileList.length; i++) {
        for (let j = 0; j < fileList.length; j++) {
          if (i != j && fileList[i].name == fileList[j].name) {
            if (!this.str.includes(fileList[i].name)) {
              this.str.push(fileList[i]);
              this.str.push(fileList[i].name);
              this.strName.push(fileList[i].name);
            }
          }
        }
      }
      if (this.str.length > 0) {
        this.isShow = true;
      }
      return true;
    },
    // 改变事件
    // 删除文件
    removeFile(uid) {
      this.$http({
        url: "/caseInfo/delScan",
        method: "POST",
        data: {
          caseId: this.$store.state.caseId,
          stageName: this.$store.state.stageNameId,
          stageCount: this.$store.state.stageCountId,
          fileNumber: "12",

          fileId: uid,
        },
      })
        .then(({ data }) => {
          this.$message({ message: "删除成功", type: "success", duration: 1500 });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 改变事件
    handleSuccess(file, fileList) {
      if (!this.ifType(file, fileList)) {
        fileList.pop();

        return;
      }

      this.file = file;

      this.fileList = fileList;
      this.$emit("shangchuan", true);
      //需要上传的文件

      this.scwjadd = "N"; //是否新建
      this.flag = false;
    },
    // 删除文件
    remove(val) {
      this.$confirm("你是否要删除文件", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.fileList = this.fileList.filter((item) => {
            if (item.uid != val.uid) {
              return true;
            }
            if (val.id) {
              this.removeFile(val.id);
            }
          });
        })
        .catch((err) => {
          console.log(err);
          return;
        });
    },
    baocun() {
      this.fileList.forEach((item, index) => {
        this.file = item;

        //  this.upLoadFile();
      });
    },
    upFileUrl() {
      let CaseScan = {};
      this.$http({
        url: "/caseInfo/stepEightScan",
        method: "POST",
        data: {
          caseId: this.$store.state.caseId,
          // 病例阶段
          stageName: this.$store.state.stageNameId,
          // 病例次数
          stageCount: this.$store.state.stageCountId,
          add: "N",
          ossFiles: this.uplist,
        },
      })
        .then(({ data }) => {
          this.getScan();
          this.$message({
            message: "保存成功上传成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.visible = false;
              this.$emit("refreshDataList");
            },
          });
        })
        .catch((err) => {
          this.$message({ message: "保存失败上传失败", type: "error", duration: 1500 });
          console.log(err);
        });
    },
    // 上传文件
    async upLoadFile(file) {
      console.log(file);
      return new Promise((obj, rej) => {
        const data = {
          // 上传文件
          file: file.raw,
          // 病例 id
          caseId: this.$store.state.caseId,
          // 病例阶段
          stageName: this.$store.state.stageNameId,
          // 病例次数
          stageCount: this.$store.state.stageCountId,
          // 文件序号
          fileNumber: "12",
          //是否新增
          add: "N",
        };
        /*    var eightFormdata = new FormData();
                     for (var k in data) {
                         eightFormdata.append(k, data[k]);
                     }
                     this.$http({
                         url: "/caseInfo/stepEight",
                         method: "POST",
                         data: eightFormdata,
                     }).then(({
                         data
                     }) => {
                         this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                             this.loadingInstance.close();
  
                         });
                         this.$message({
                             message: "保存成功上传成功",
                             type: "success",
                             duration: 1500,
                             onClose: () => {
                                 this.visible = false;
                                 this.$emit("refreshDataList");
                             }
                         });
                         this.$router.push("/particulars");
                         this.$store.commit("indexRemove", 2);
                     }).catch(err => {
                         this.loadingInstance.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
                             this.loadingInstance.close();
  
                         });
                         this.$message({ message: '保存失败上传失败', type: 'error', duration: 1500 })
                        console.log(err)
                     })  */
        console.log(this.token, "jbbl");
        var client = new OSS({
          region: "oss-" + this.token.ossAddress, // oss地址
          accessKeyId: this.token.accessKeyId, // 通过阿里云控制台创建的AccessKey ID。
          accessKeySecret: this.token.accessKeySecret, // 通过阿里云控制台创建的AccessKey Secret。
          stsToken: this.token.securityToken,
          bucket: this.token.bucketName, // 仓库名字
          useFetch: true, // 支持上传大于100KB的文件
          // secure:true // 返回的url为https
        });
        let stageName = this.$store.state.stageNameId;
        let stage = "";
        if (stageName == 1) {
          stage = "FirstDesign";
        } else if (stageName == 2) {
          stage = "MidTermRestart";
        } else if (stageName == 3) {
          stage = "FinalAdjustment";
        } else if (stageName == 4) {
          stage = "AfterProduction";
        }

        var fileName =
          this.$store.state.caseId +
          "/" +
          stage +
          "/" +
          new Date().getTime() +
          data.file.name.substr(data.file.name.indexOf("."));

        console.log(fileName);
        client
          .multipartUpload(fileName, data.file)
          .then((res) => {
            obj();

            // console.log(res);

            let params = {
              // 病例 id
              caseId: this.$store.state.caseId,
              // 病例阶段
              stageName: this.$store.state.stageNameId,
              // 病例次数
              stageCount: this.$store.state.stageCountId,
              // 文件序号
              fileNumber: "12",
              //是否新增
              add: "N",
              ossfilePath: res.name,
              fileName: data.file.name,
            };
            this.uplist.push({
              ossfileName: res.name.substr(
                res.name.lastIndexOf("/") + 1,
                res.name.length
              ),
              ossfilePath: res.name,
              filename: data.file.name,
              fileNumber: "12",
              caseid: this.$store.state.caseId,
              fileType: "S",
              phaseid: phaseid,
            });

            if (this.uplist.length == this.fileList.length) {
              this.upFileUrl();
            }
          })
          .catch((err) => {
            rej();
            console.log(err);
          });
      });
    },
    newLodImg() {
      var eightFormdata = new FormData();

      eightFormdata.append("caseId", this.$store.state.binglId);
      this.$http({
        url: "/caseInfo/getCaseInfoById",
        method: "POST",
        data: eightFormdata,
      })
        .then((data1) => {
          let res = JSON.parse(data1.data.data);

          this.caseNo = res.caseinfoId;
          this.token.accessKeySecret = res.accessKeySecret;
          this.token.accessKeyId = res.accessKeyId;
          this.token.securityToken = res.securityToken;
          this.token.ossAddress = res.ossAddress;
          this.token.bucketName = res.bucketName;
          console.log(this.token);
          /* if (data.code == 200) {
  
                    console.log(data);
  
                  } */

          const data = {
            // 上传文件
            file: this.fileList[0].raw,
            // 病例 id
            caseId: this.$store.state.binglId,
            // 病例阶段
            stageName: this.$store.state.stageName,
            // 病例次数
            stageCount: this.$store.state.stageCount,
            // 文件序号
            fileNumber: 12,
            //是否新增
            add: "Y",
          };
          var client = new OSS({
            region: "oss-" + this.token.ossAddress, // oss地址
            accessKeyId: this.token.accessKeyId, // 通过阿里云控制台创建的AccessKey ID。
            accessKeySecret: this.token.accessKeySecret, // 通过阿里云控制台创建的AccessKey Secret。
            stsToken: this.token.securityToken,
            bucket: this.token.bucketName, // 仓库名字
            useFetch: true, // 支持上传大于100KB的文件
            // secure:true // 返回的url为https
          });
          let stageName = this.$store.state.stageName;
          let stage = "";
          if (stageName == 1) {
            stage = "FirstDesign";
          } else if (stageName == 2) {
            stage = "MidTermRestart";
          } else if (stageName == 3) {
            stage = "FinalAdjustment";
          } else if (stageName == 4) {
            stage = "AfterProduction";
          }
          console.log(this.caseNo);
          var fileName =
            this.erpcaseno +
            "/" +
            stage +
            "/" +
            data.fileName +
            "_" +
            new Date().getTime() +
            data.file.name.substr(data.file.name.indexOf("."));

          client
            .multipartUpload(fileName, data.file)
            .then((res) => {
              console.log(res);

              let params = {
                // 病例 id
                caseId: this.$store.state.binglId + "",
                // 病例阶段
                stageName: this.$store.state.stageName,
                // 病例次数
                stageCount: this.$store.state.stageCount,
                // 文件序号
                fileNumber: "12",
                //是否新增
                add: "Y",
                ossfilePath: res.name,
                fileName: data.file.name,
              };
              var eightFormdata = new FormData();
              for (var k in params) {
                eightFormdata.append(k, params[k]);
              }
              this.$http({
                url: "/caseInfo/stepEightScan",
                method: "POST",
                data: eightFormdata,
              })
                .then(({ data }) => {
                  this.$message({
                    message: "保存成功上传成功",
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      this.visible = false;
                      this.$emit("refreshDataList");
                    },
                  });

                  this.$router.push("/particulars");
                  this.$store.commit("indexRemove", 2);
                })
                .catch((err) => {
                /*   this.loadingInstance.$nextTick(() => {
                    // 以服务的方式调用的 Loading 需要异步关闭
                    this.loadingInstance.close();
                  }); */
                  this.$message({
                    message: "保存失败上传失败",
                    type: "error",
                    duration: 1500,
                  });
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteimg() {
      // 删除 图片 接口
      this.$http({
        url: "/caseInfo/delScan",
        method: "POST",
        data: {
          // 病例 id
          caseId: this.$store.state.binglId,
          // 病例阶段
          stageName: this.$store.state.stageName,
          // 病例次数
          stageCount: this.$store.state.stageCount,
          // 文件序号
          fileNumber: 12,
        },
      })
        .then(({ data }) => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    fileList: function () {
      this.setupFileList();
    },
  },
};
</script>
<style scoped lang="less">
.footer {
  margin-top: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.space {
  margin-right: 10px;
}

.qjxl {
  .title {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  span,
  .title {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ff0000;
  }

  .jjdz {
    display: flex;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    margin-bottom: 16px;

    .space {
      margin-left: 10px;
    }

    & > div:first-of-type {
      width: 90px;
    }
  }

  .order-btn {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;

    .active {
      color: #1275d2;
      text-decoration: underline;
    }
    & > div {
      width: auto !important;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.qjsj {
  padding-left: 12px;
  width: 300px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #1275d2;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
    //transform: translateY(-50%);
  }

  .qjsj-box {
    left: 0;
    width: 560px;
    height: 160px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    position: absolute;
    background: #ffffff;
    top: 42px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .qjsj-day {
      padding: 16px 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      div {
        cursor: pointer;
        width: 160px;
        height: 28px;
        font-size: 14px;
        border: 1px solid #1275d2;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .active {
        background: #1275d2;
        color: #ffffff;
      }
    }

    .qjsj-line {
      margin: auto;
      width: 540px;
      height: 1px;
      background: #999999;
    }

    .qjsj-hour {
      margin-right: -18px;
      flex-shrink: 1;
      overflow-y: scroll;
      display: flex;
      padding-left: 16px;
      align-items: center;
      flex-wrap: wrap;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 16px;
        margin-right: 18px;
        width: 90px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #1275d2;
        display: flex;
        justify-items: center;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;

        p {
          margin-top: 0;
        }
      }

      div:nth-of-type(5n) {
        margin-right: 0;
      }

      .active {
        background: #1275d2;
        color: #ffffff;
      }
    }
  }
}

.close::after {
  top: 19%;
  border: 8px solid transparent;
  border-bottom: 8px solid #1275d2;
}

.click-select {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 10px;
  padding-right: 35px;
  background: white;
  z-index: 10;
  width: 300px;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #1275d2;
  &.active::after {
    top: 19%;
    border: 8px solid transparent;
    border-bottom: 8px solid #1275d2;
  }
  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
  }
}
/deep/ .express {
  height: 448px;

  .el-input__inner {
    margin-left: 1px;
    margin-top: 1px;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: transparent;
    color: transparent;
  }

  .edit-box {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }

  .code {
    margin-top: 32px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #0075f6;
  }

  .detail {
    align-self: flex-start;
    margin-top: 32px;
    margin-left: 0;
  }

  .text {
    margin-top: 2px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
  }
}

.copy-icon {
  cursor: pointer;
  align-self: end;
}

.address {
  margin-top: 32px;
  display: flex;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
}

/deep/ .tangcform {
  padding-top: 20px;
}

.blue-btn {
  cursor: pointer;
  width: 96px;
  height: 28px;
  background: #1275d2;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
}

.img-box {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 9px;

  img {
    width: 100%;
    display: block;
  }
}

.table {
  /deep/ th {
    border-bottom: #1275d2 2px solid;
  }

  /deep/ td {
    cursor: pointer;
  }

  // 修改鼠标经过表格的颜色
  /deep/ .el-table tbody tr:hover > td {
    // 可以选择隐藏
    background-color: transparent !important;
    opacity: 0.8;
  }
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog__header {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}

/deep/ .el-dialog__title {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  line-height: 32px;
  padding: 0;
}

/deep/.el-dialog__headerbtn {
  top: 8px;
}

/deep/.el-dialog__header {
  height: 32px;
  background: #1275d2;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.dialog-div {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.canel {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #0075f6;
}

.submit {
  cursor: pointer;
  margin-right: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 24px;
  background: #1275d2;
  border-radius: 4px;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
}
.submit-btn{
  margin-right: 0;
}
.dialog-box {
  padding-left: 24px;

  p {
    margin-top: 8px;
  }

  .qjsj {
    /deep/.el-input__inner {
      margin-top: 8px;
      width: 400px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #1275d2;
    }
  }

  /deep/ .el-icon-arrow-up:before {
    color: #1275d2;
  }
}

.div-text {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
  margin-top: 32px;
}

.search-box {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-left: 24px;
  padding-right: 15px;
  padding-top: 32px;
  padding-bottom: 36px;

  /deep/ .el-input {
    width: auto;
  }

  /deep/.el-input__inner {
    margin-top: 0;
    width: 240px;
    height: 32px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
  }

  /deep/ .el-input__icon {
    line-height: 32px;
  }

  .btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 32px;
    background: #1275d2;
    border-radius: 4px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
  }
}

.del {
  cursor: pointer;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ff0000;
  line-height: 20px;
  text-decoration: underline;
}

.xuanze {
  cursor: pointer;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #1275d2;
  line-height: 20px;
  text-decoration: underline;
}

.table-box {
  width: 772px;
  margin-left: 76px;
  margin-top: 40px;

  /deep/ .el-table {
    background: transparent;

    .el-table__header {
      background: transparent;
    }

    th {
      border-bottom: 2px #1a95eb solid;
    }

    th,
    tr {
      background-color: transparent;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 20px;
    }
  }
}

.blue-bg {
  background-color: #1275d2;
  color: #ffffff !important;
}

.detail-address {
  margin-left: 20px;
}

.detail {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  margin-left: 76px;
  display: flex;
  align-items: center;

  span {
    color: #ff0000;
  }
}

.radio-div {
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;

  div {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    cursor: pointer;
    margin-right: 32px;
    padding: 0 20px;
    height: 28px;
    border-radius: 16px;
    border: 1px solid #1275d2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.radio-box {
  margin-top: 42px;
  margin-left: 76px;
  margin-bottom: 40px;
}

/deep/.upload-demo {
  c .el-upload-list {
    position: absolute;
    left: 50%;
    top: 225px;
    transform: translateX(-50%);
    width: 200px;

    .el-upload-list__item-status-label,
    .el-icon-close {
      top: -238px;
      right: -202px;
      opacity: 0;
      display: block;
    }

    .el-upload-list__item-status-label {
      .el-icon-upload-success {
        width: 604px;
        height: 300px;
      }
    }

    .el-icon-close {
      width: 604px;
      height: 300px;
    }
  }
}

.scwj {
  height: 550px;
  position: relative;
}

.wenjscbox {
  position: relative;

  left: 76px;

  .upFile {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .fileName {
      max-width: 160px;
      padding-right: 10px;
      height: 20px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn {
      cursor: pointer;
      color: red;
    }
  }
}

.wenjscbox {
  width: 600px;

  img {
    width: 26px;
    height: 26px;
    margin-right: 8px;
  }

  .btn {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    line-height: 20px;
  }
}

.show-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0e, 0.5);
  z-index: 9999;
  top: 0;
  left: 0;

  & > div {
    position: absolute;
    background: #f9f9f9;
    width: 500px;
    height: 200px;
    left: 50%;
    top: 50%;

    div {
      margin-top: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      p {
        display: inline-block;
        max-width: 150px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    transform: translate(-50%, -50%);

    .top {
      margin-top: 0;
      height: 20px;
      background: #333333;
    }

    .footer {
      position: absolute;
      bottom: 20px;
      display: flex;
      left: 50%;
      transform: translateX(-50%);

      & > div {
        margin-right: 50px;
        background: #1175d2;
        border-radius: 5px;
        padding: 5px;
        color: #f9f9f9;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}
.disabled {
  color: #666666;
}
.my-select {
  /deep/ .el-select-dropdown__list {
    padding-bottom: 20px;
  }
}
/deep/ .el-radio__inner {
  width: 20px;
  height: 20px;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #fff !important;
  width: 20px;
  height: 20px;
  &::after {
    width: 10px;
    height: 10px;
    background: #1175d2;
    border-radius: 50%;
  }
}
.jkd {
  /deep/ .el-dialog__close {
    color: #ffffff;
  }
  /deep/.el-dialog__body {
    overflow: inherit;
  }
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 1660px) {
  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .butonezu .el-button {
    transform: scale(0.8);
    margin: 0px;
  }

  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu .el-button {
    transform: scale(0.8);
    margin: 0px;
    width: 140px;
    float: left;
    display: block;
  }

  #particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu {
    width: 140px !important;
    // bottom: -36% !important;
    right: 2% !important;
  }
  #particulars-schedule /deep/ .el-dialog {
    margin-top: 18vh !important;
    transform: scale(0.9);
    height: 475px !important;
  }
  #particulars-schedule .el-dialog .el-dialog__body .koko {
    margin-bottom: 20px !important;
  }

  #particulars-schedule .el-dialog .el-dialog__body .wrap {
    height: 300px !important;
  }
}

@media screen and (max-width: 1360px) {
  #particulars-schedule /deep/ .el-dialog {
    transform: scale(0.8);
    height: 450px !important;
  }
  #particulars-schedule .el-dialog .el-dialog__body .koko {
    margin-bottom: 20px !important;
  }

  #particulars-schedule .el-dialog .el-dialog__body .wrap {
    height: 300px !important;
  }
}
</style>
<style scoped lang="less">
.dialog-box {
  padding-left: 24px;

  p {
    margin-top: 8px;
  }

  .qjsj {
    /deep/.el-input__inner {
      margin-top: 8px;
      width: 400px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #1275d2;
    }
  }

  /deep/ .el-icon-arrow-up:before {
    color: #1275d2;
  }
}

.detail {
  align-self: flex-start;
  margin-top: 32px;
  margin-left: 0;
}
.click-select {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 10px;
  padding-right: 35px;
  background: white;
  z-index: 10;
  width: 300px;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #1275d2;
  &.active::after {
    top: 19%;
    border: 8px solid transparent;
    border-bottom: 8px solid #1275d2;
  }
  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
  }
}
/deep/ .express {
  height: 448px;

  .el-input__inner {
    margin-left: 1px;
    margin-top: 1px;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: transparent;
    color: transparent;
  }

  .edit-box {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }

  .code {
    margin-top: 32px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #0075f6;
  }
  .my-select {
    /deep/ .el-select-dropdown__list {
      padding-bottom: 20px;
    }
  }

  .detail {
    align-self: flex-start;
    margin-top: 32px;
    margin-left: 0;
  }

  .text {
    margin-top: 2px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
  }
}
.code {
  margin-top: 32px;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #0075f6;
}
.div-text {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
  margin-top: 32px;
}
.jkd {
  /deep/ .el-dialog__close {
    color: #ffffff;
  }
  /deep/.el-dialog__body {
    overflow: inherit;
  }
}
.btn-box {
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    color: #1275d2;
    font-size: 12px;
    margin-right: 25px;
    cursor: pointer;
  }
  img {
    width: 13px;
    margin-right: 7px;
  }
}
.address {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .logistics {
    width: 400px;
    height: 592px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);

    .title {
      height: 32px;
      background: #343b43;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      padding: 0 20px;
      margin-bottom: 16px;
      span:nth-of-type(2)::after {
        content: "X";
        cursor: pointer;
      }
    }
    .content {
      padding-left: 20px;
    }
    .item {
      display: flex;

      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      p:nth-of-type(1) {
        width: 64px;
      }
      p:nth-of-type(2) {
        width: 180px;
      }

      .icon-img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .btn {
      margin-top: 12px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1275d2;
      padding-bottom: 8px;

      border-bottom: #1275d2 solid 1px;
      & > div {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #1275d2;
        margin-right: 26px;
        img {
          margin-right: 2px;
        }
      }
    }
    .list {
      height: 415px;
      overflow: auto;
      .last-box {
        &::before {
          content: "";
          position: absolute;
          z-index: 99;
          left: 0;
          width: 10px;
          height: 10px;
          background: #bababa !important;
          border-radius: 50%;
        }
      }
      & > div {
        display: flex;

        & > div:nth-of-type(1) {
          width: 80px;

          font-size: 12px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #666666;
        }
        & > div:nth-of-type(2) {
          width: 230px;
          padding-bottom: 20px;
          display: flex;
          font-size: 14px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;

          position: relative;
          overflow: hidden;
          padding-left: 20px;
          .first {
          }

          .line {
            position: absolute;
            left: 5px;

            border-left: 1px dashed #0089ff;

            height: 100%;
          }
          &::before {
            position: absolute;
            left: 0;
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: #0089ff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.planFlag {
  /deep/.el-button {
    background: #666 !important;
    border: none !important;
    span {
      color: #fff !important;
    }
  }
}
a {
  text-decoration: none;
  color: #fda21c;
  &:hover {
    color: #333;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  //针对ie
  #particulars-schedule .el-dialog .el-dialog__body .wrap .el-timeline-item__wrapper {
    top: -7px;
  }
}
#particulars-schedule {
  overflow: auto;

  .el-timeline-item {
    height: 70px;
    padding-bottom: 34px;

    .el-timeline-item__dot {
      .el-image {
        margin-left: -8px;
        width: 26px;
        height: 26px;
      }
    }

    /deep/.el-timeline-item__wrapper {
      position: relative;
      padding-left: 36px;
      font-size: 16px;
    }

    /deep/ .el-timeline-item__tail {
      border-left-style: dashed;
    }

    .line {
      height: 0;
      width: 94%;
      position: absolute;
      bottom: -34px;
      right: 0;
      border: 0.5px solid #d6d6d6;
    }

    /deep/.buttwozu {
      // height:36px;
      // width:100%;
      position: absolute;
      right: 2%;
      bottom: 0%;
      z-index: 1;

      .el-button {
        width: 140px;
        height: 36px;
        padding: 0;
        line-height: 36px;
        border: 1px solid #f99400;
        box-sizing: border-box;
        background: #f9f9f9;

        span {
          color: #fda21c;
        }

        // span:hover {
        // 	color: #fff;
        // }
      }

      // .el-button:hover {
      //   background-color: red;
      // }

      .el-button:active {
        // background-color:#1175d2;
      }
    }

    .butonezu {
      // height:36px;
      // width:100%;
      position: absolute;
      right: 2%;
      // margin-top:-15%;
      bottom: 0%;
      z-index: 1;

      /deep/.el-button {
        width: 140px;
        height: 36px;
        padding: 0;
        line-height: 36px;
        background: #f99400;
        border: none;

        span {
          color: #fff;
        }
      }
    }

    .huyi2:hover {
      background-color: #fda21c !important;
    }

    // .line:last-child{
    //     border:none;
    // }
  }

  /deep/ .el-dialog {
    width: 570px;
    height: 200px;
    // margin-top: 18vh !important;
    background: #f9f9f9;
    padding-left: 0px !important;

    .el-dialog__header {
      padding: 25px 25px 0 40px;

      .el-dialog__title {
        font-weight: normal;
        padding: 0;
      }

      .el-dialog__headerbtn {
        top: 15px;
        right: 17px;
      }

      .el-dialog__headerbtn .el-dialog__close {
        width: 14px;
        height: 14px;
        color: #4b4b4b;
      }
    }

    .el-dialog__body {
      padding: 0;
      text-align: center;
      font-size: 16px;

      .trackingNumber {
        text-align: left;
        margin-bottom: 10px;
      }

      .koko {
        overflow: hidden;
        margin-bottom: 48px;

        .p1 {
          margin-right: 60px;
        }
      }

      .wrap {
        height: 420px;
        padding-right: 120px;
        overflow: auto;
        .el-timeline-item {
          height: auto;
        }
        .el-timeline {
          padding-top: 10px;
        }
        .el-timeline-item__wrapper {
          width: 70%;
          padding-left: 168px;
          top: -12px;
        }
        .el-timeline-item__timestamp {
        }
        .el-timeline-item__tail {
          height: 100%;
          border-left-style: solid;
          border-left-color: #999999;
          left: 129px;
          // top:22px;
        }
        .el-timeline-item__node--normal {
          left: 120px;
          width: 20px;
          height: 20px;
        }
        .el-timeline-item__content,
        .el-timeline-item__timestamp {
          text-align: left;
        }
        .el-timeline-item__timestamp.is-bottom {
          margin-top: 5px;
          color: #666 !important;
        }
        .time {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 16px;
        }
        .date {
          color: #333 !important;
        }
        .specificTime {
          color: #666 !important;
        }
      }
    }
  }
}
</style>

<style scoped lang="less">
@media screen and (max-width: 1660px) {
  #particulars-schedule .el-timeline-item .butonezu .el-button {
    transform: scale(0.8) !important;
    margin: 0px !important;
  }

  #particulars-schedule .el-timeline-item .buttwozu .el-button {
    transform: scale(0.8) !important;
    margin: 0px !important;
    width: 140px !important;
    float: left !important;
  }

  #particulars-schedule .el-timeline-item .buttwozu {
    width: 140px !important;
    // bottom: -36% !important;
    right: 2% !important;
  }
}

@media screen and (max-width: 1360px) {
}
</style>
<style lang="less" scoped>
.el-timeline .el-timeline-item {
  position: relative;
}
.reminder {
  position: absolute;
  top: 0;
  left: 165px;
  width: 260px;
  height: 80px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 2px 2px 5px #aaa;
  justify-content: space-between;
  box-sizing: border-box;
  background: #fff;
  z-index: 5;
  .top,
  .bottom {
    font-size: 14px;
    color: #333;
    i {
      font-style: normal;
    }
    span:nth-of-type(1) {
      color: #1175d2;
    }
    span:nth-of-type(2) {
      margin-right: 26px;
    }
  }
  .bottom {
    margin-top: 10px;
    span:nth-of-type(1) {
      margin-right: 13px;
    }
    span:nth-of-type(2) {
      margin-right: 34px;
    }
  }
}

.reminderStyle {
  position: absolute;
  top: 3px;
  left: 138px;
  width: 20px;
  height: 20px;
}
/deep/ .el-dialog {
    width: 570px;
    height: 200px !important;
    // margin-top: 18vh !important;
    background: #f9f9f9;
    padding-left: 0px !important;
    }
    /deep/ .el-dialog__header{
      height:50px;
      padding:0 !important;
      padding-left:20px !important;
        display:flex;
        align-items:center;
    }
</style>
