<template>

 
<el-dialog
      class="jkd"
      title="寄快递"
      :visible.sync="dialogVisible3"
      width="560px"
      :before-close="handleClose"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-box express" @click="isQjsj = false" 
     
      >
        <div class="code">病例编号：{{ erpcaseno }}</div>
        <div class="text">邮寄硅橡胶模型时，请附病例编号一同寄出。</div>
        <div class="detail" style="flex-shrink: 0">
          <div><span>*</span> 寄件地址：</div>

          <div>
            <div
              @click="clickSelect()"
              class="click-select"
              :class="{ active: selectActive }"
            >
              {{ stringStr(dizAddress) }}
            </div>
            <el-select
			
              ref="myselect"
              v-model="diz"
              @change="selectChange()"
              @blur="selectChange()"
              :disabled="isEdit"
              popper-class="my-select"
            >
              <el-option
                v-for="(item, i) in dizshuzu"
                v-if="item.del != 1 || item.id == diz"
                :key="i"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
        
          </div>
         
        </div>
        <div class="detail">
          <div style="align-self: flex-start; flex-shrink: 0">收件地址：</div>

          <div>
            <div style="width: 410px">
              {{
                jjxl.sjdz.province + jjxl.sjdz.city + jjxl.sjdz.area + jjxl.sjdz.address
              }}  {{ jjxl.sjdz.deliveryName }} {{ jjxl.sjdz.contactNumber }}
            </div>
            <div>
            病例编号：{{erpcaseno}} 患者姓名：{{$store.state.patientName}}
            </div>
          </div>
        </div>
        <div class="detail">
          <div><span>*</span> 预约取件：</div>

          <div class="qjsj" :class="{ close: isQjsj }" @click.stop="isQjsj = true">
            {{ qjsj.txt }}
            <div class="qjsj-box" v-if="isQjsj">
              <div class="qjsj-day">
                <div :class="{ active: jt.checked }" @click="checkDay(jt)">
                  {{ jt.txt }}{{ jt.time }}
                </div>
                <div :class="{ active: mt.checked }" @click="checkDay(mt)">
                  {{ mt.txt }}{{ mt.time }}
                </div>
                <div :class="{ active: ht.checked }" @click="checkDay(ht)">
                  {{ ht.txt }}{{ ht.time }}
                </div>
              </div>
              <div class="qjsj-line"></div>
              <div class="qjsj-hour">
                <div
                  v-for="item in hoursList"
                  @click.stop="checkHours(item)"
                  :class="{ active: item.checked }"
                >
                  <p v-if="item.id != 0">
                    {{ item.start + "~" + item.end }}
                  </p>
                  <p v-if="item.id == 0 && nowH > 7">一小时内</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="submit" @click="submit(3, isEdit)">下单</div>
          <div class="canel" @click="canel(3)">取消</div>
        </div>
      </div>

     
    </el-dialog> 


</template>
<script>
	import {
		dateToStr
	} from "@/util/validate.js";

	export default {
  
		components: {
			
  },
  props:{
   
    dialogVisible3:{
                require: false,
                type: Boolean
            },
  },
		data() {
			return {
      type:-1,
				selectActive: false,
      strName: [],
      dizAddress: "",
      isShow: false,
      str: [],
      yjmx: [],
      dialogVisible5: false,
      takeNo: "",
      isEdit: true,
      empPhone: "",
      //寄件信息
      jjxl: {
        jjdz: {},
        sjdz: {},
        yyqj: "",
      },
      nowH: "",
      isOpen: true,
      //寄件信息
      caseNo: "",
      caseNoId: "", //寄件单号
      ht: { id: 3, checked: false },
      mt: { id: 2, checked: false },
      jt: { id: 1, checked: false },
      qjsj: {}, //取件时间
      diz: "",
      hoursList: [],
      dizshuzu: [],
      address: {
        province: "天津市",
        city: "天津市",
        area: "东丽区空港经济区",
        address: "中环西路56号中科天保智谷9号楼正丽科技 ",
        deliveryName: "正丽科技 ",
        phone: "15339368485",
      },
      origin: "", //
      selectedArrData: [], // 把选择到的当前行的id存到数组中
      imgEidt: require("@/assets/address_edit.png"),
      imgCopy: require("@/assets/copy.png"),
      imgUrl: require("@/assets/check-circle.png"),
      search: "",
      dialogVisible2: false,
      dialogVisible: false,
    
      dialogVisible4: false, //邮寄地址选择
      isQjsj: false,
      radio: 1, //上传类型
      upType: 1, //上传方式
      mailType: 0, //邮寄方式
      tableData: [],
				erpcaseno:'',
				  address:'',
				  SFNo:'',
				imgUrl: require("@/assets/copy.png"),

				centerDialogVisible: false,
				reverse: false,
				reverse2: false,
				isLogisticsInfo:false,
				logisticsInfoList:[],
				type: [],
				status: [
					require("@/assets/2.2病例详情/已完成.png"), 
					require("@/assets/2.2病例详情/进行中.png"), 
				],
				reminder:[require("@/assets/2.2病例详情/reminder.png")],
				activities2: [],
				activities: [],
				tokeno:'',
				phone:'',
				receiver:'',
				arrflag:[false,false,false,false]
			};
		},
    created(){
        
      this.timeFormat();
      this.checkDay(this.jt)


    
    },
    activated(){},
		   mounted() {
 this.$store.commit('changeCopy',true)
  
   this.dizhijieko();
    this.timeFormat();
	this.getSjdz()
  this.init();
  this.checkDay(this.jt)

			
		},
   
		methods: {
			handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          this.$emit('dialogVisible3')
        
          done();
        })
        .catch(() => {});
    },
			timeFormat() {
      let time = new Date();
      let y = time.getFullYear();
      let m = this.addZero(time.getMonth() + 1);
      let d = this.addZero(time.getDate());
      let h = time.getHours();
      this.jt.txt = "今天";
      this.jt.time = `${y}.${m}.${d}`;
      this.jt.formatTime = `${y}-${m}-${d}`;
      this.jt.h = h;
      time = new Date(new Date().getTime() + 3600000 * 24);
      y = time.getFullYear();
      m = this.addZero(time.getMonth() + 1);
      d = this.addZero(time.getDate());
      h = time.getHours();
      this.mt.txt = "明天";
      this.mt.time = ` ${y}.${m}.${d}`;
      this.mt.formatTime = `${y}-${m}-${d}`;
      time = new Date(new Date().getTime() + 3600000 * 24 * 2);
      y = time.getFullYear();
      m = this.addZero(time.getMonth() + 1);
      d = this.addZero(time.getDate());
      h = time.getHours();
      this.ht.txt = "后天";

      this.ht.time = ` ${y}.${m}.${d}`;
      this.ht.formatTime = `${y}-${m}-${d}`;
    },
    addZero(val) {
      if (val < 10) {
        return "0" + val;
      } else {
        return val;
      }
    },
	
			getSFById(isflg) {
        console.log('zzzzzzzzzzzzzzz');
      this.$set(this.jjxl.jjdz, "address", "");
      this.$set(this.jjxl.jjdz, "contactNumber", "");
      this.$set(this.jjxl.jjdz, "deliveryName", "");
      this.$set(this.jjxl, "yyqj", "");

      this.yjmx = [];
      this.edit = 1;
      this.cancel = 1;
      this.state = "";

      let data = { caseIds: this.$store.state.caseId, mailingMethod:1 }; //病例id

      this.$http({
        url: "/webCase/opGetSFById",
        method: "post",
        dataType: "json",
        data: data,
        contentType: "application/x-www-form-urlencoded",
      })
        .then(({ data }) => {
        
          if (data.code == 200) {
          
            

            let res = JSON.parse(data.data);
            console.log(res);
            if (res != null) {
              this.state = res.state;
              this.edit = res.edit;
              this.cancel = res.cancel;

              this.caseNoId = res.orderNo;
              this.type=res.orderState
              let sj = "";
              /*  * 寄件地址：
                       中国上海上海闵行万金中心10F
  
                       海波13641636968
  
                       收件地址：
                       中环西路86号中科天保智谷9号楼
  
                       正丽科技质检部15890128745
  
                           * 预约取件：
                       今天(2022.12.21) 18: 00~19: 00 */

              if (res.mailingModel == 1) {
                this.yjmx = [1];
              } else if (res.mailingModel == 3) {
                this.yjmx = [1, 2];
              } else if (res.mailingModel == 2) {
                this.yjmx = [2];
              }
              console.log(this.yjmx);
              if (res.mailingMethod) {
                this.mailType = Number(res.mailingMethod);
              }
              if (res.orderState != "1") {
                let time = new Date();

                time = new Date(res.bookingTime);
                let time2 = new Date(time.getTime() + 1000 * 60 * 60);
                let jt = new Date();
                let Y = jt.getFullYear();
                let M = jt.getMonth() + 1;
                let D = jt.getDate();

                let mt = new Date(
                  new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                    24 * 3600 * 1000
                );
                let ht = new Date(
                  new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                    48 * 3600 * 1000
                );
                let rq = "";
                console.log('zzzz')
                 console.log( new Date().getDate(),new Date().getMonth())
                if (
                  time.getDate() == new Date().getDate() &&
                  time.getMonth() == new Date().getMonth()
                ) {
                  rq = "(今天)";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 1000 * 60 * 60);
                  } else {
                    time2 = new Date(time.getTime() + 1000 * 60 * 30);
                  }
               
                } else if (time.getDate() == mt.getDate() &&
                  time.getMonth() ==mt.getMonth()) {
                  rq = "（明天）";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 25 * 1000 * 60 * 60);
                  } else {
                    time2 = new Date(
                      time.getTime() + 24 * 1000 * 60 * 60 + 60 * 1000 * 30
                    );
                  }
                 
                } else {
                if(time.getDate() == mt.getDate() &&
                  time.getMonth() ==mt.getMonth()){
                  rq = "(后天)";
                }
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 49 * 1000 * 60 * 60);
                  } else {
                    time2 = new Date(
                      time.getTime() + 48 * 1000 * 60 * 60 + 60 * 1000 * 30
                    );
                  }
                }
                  sj =
                    `${rq} ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                  ${
                    this.addZero(time.getHours()) +
                    ":" +
                    time.getMinutes() +
                    ":" +
                    this.addZero(time.getSeconds())
                  } ~ 
                  ${
                    this.addZero(time2.getHours()) +
                    ":" +
                    time2.getMinutes() +
                    ":" +
                    this.addZero(time2.getSeconds())
                  } `;
                

                // }
                // this.$set( this.jjxl,'yyqj',  '')
                this.empPhone = res.empPhone;
                this.takeNo = res.takeNo;
                   //   console.log(this.dizshuzu);
                this.dizshuzu.filter((item) => {
			
                  console.log(item.id, res.sendAddressIdWeb);
                  if (
                    item.id == res.sendAddressIdOrtho ||
                    item.id == res.sendAddressIdWeb
                  ) {
				
                    this.$set(
                      this.jjxl.jjdz,
                      "address",
                      item.country + item.province + item.city + item.area + item.address
                    );
				
					this.dizAddress=this.jjxl.jjdz.address
                    this.$set(this.jjxl.jjdz, "contactNumber", item.contactNumber);
                    this.$set(this.jjxl.jjdz, "deliveryName", item.deliveryName);
                    this.$set(this.jjxl, "yyqj", sj);
                  }
                });
              }
            }
          }
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
			   //收件地址
			   getSjdz() {
      this.$http({
        url: "/webCase/optoAddress",
        method: "post",
      })
        .then(({ data }) => {
          this.isOpen = true;

          if (data.code == 200) {
            let res = JSON.parse(data.data);
            // console.log(res);
            this.jjxl.sjdz.address = res.address;
            this.jjxl.sjdz.city = res.city;
            this.jjxl.sjdz.province = res.province;
            this.jjxl.sjdz.area = res.county;
            this.jjxl.sjdz.contactNumber = res.tel;
            this.jjxl.sjdz.deliveryName = res.contact;
            // console.log(this.jjxl.sjdz);
          }
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
			stringStr(dizAddress){
        let str=''
         if(dizAddress&&dizAddress.length>53){
            str=dizAddress.substr(0,53)+'...'
         }else{
         str= dizAddress
         }
          return  str
      },
			getExpressSend(params){
				this.$http({
						url: "/caseInfo/getExpressSend",
						method: "POST",
						params
							
						
				})
					.then(({
						data
					}) => {
						// console.log(data)
						if (data.code == 200) {
							
							 let res=  JSON.parse(data.data);
							  this.address=res.address
							  this.SFNo=res.takeNo
							 this.isLogisticsInfo=true
						
							
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			init() {

  
				this.$http({
						url: "/caseInfo/caseProcess",
						method: "POST",
						data: {
							caseId: this.$store.state.caseId
						}
					})
					.then(({
						data
					}) => {
						if (data.code == 200) {
							this.activities = JSON.parse(data.data);
                 
              this.erpcaseno=this.activities.outerNo
                console.log(this.activities)
							this.activities.forEach((el) => {
                if(el.outerNo){
              this.erpcaseno=el.outerNo

                }
								if(el.receiver){
									this.tokeno=el.takeNo;
									this.phone=el.phone;
									this.receiver = el.receiver;
								}
								el.startTime = dateToStr(new Date(el.startTime))
							});
							// this.logisticsData();
							// console.log(this.activities.reverse());
						}
   this.getSFById()
           
					})
					.catch(err => {
         
						console.log(err);
					});
        
			},
			logisticsInfo(val){
		      let  params={
				statusId:val
			  }

			
				this.$http({
						url: "/caseInfo/getExpressInfo",
						method: "POST",
						params
							
						
				})
					.then(({
						data
					}) => {
						// console.log(data)
						if (data.code == 200) {
							// console.log(JSON.parse(data.data).reverse());
							this.logisticsInfoList = JSON.parse(data.data);
							   this.getExpressSend(params)
							
							
							var that = this;
						
							// this.activities2;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			// 物流接口
			logisticsData(phone,takeNo){
				this.phone = phone;
				this.tokeno = takeNo;
				this.centerDialogVisible = true
				this.$http({
						url: "/caseInfo/getSF",
						method: "POST",
						data: {
							takeNo,
							phone
						}
				})
					.then(({
						data
					}) => {
						// console.log(data)
						if (data.code == 200) {
							// console.log(JSON.parse(data.data).reverse());
							this.activities2 = JSON.parse(data.data);
							var that = this;
							this.activities2.forEach(function (el,idx) {
									// type: 'primary',
									// color: '#f99400',
								if(idx === 0){
									el.color='#f99400';
								}else{
									el.color='#f9c67c';
								}
								el.type='primary';
							
							});
							// this.activities2;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			skip(){
				 window.open(window.location.href.replace('particulars',`three?caseId=${this.$store.state.caseId}`))
			},
			reminderClick(index){
				for(let i = 0; i<this.arrflag.length; i++){
					if(index!=i){
						this.$set(this.arrflag,i,false)
					}
				}
				this.$set(this.arrflag,index,!this.arrflag[index])
			},
			stringStr(dizAddress){
        let str=''
         if(dizAddress&&dizAddress.length>53){
            str=dizAddress.substr(0,53)+'...'
         }else{
         str= dizAddress
         }
          return  str
      },
    upChange() {
      if (this.radio == 2) {
        this.getSFById();
      }
    },
    selectChange() {
       
          this.selectActive = false;
      this.dizshuzu.filter((item) => {
        console.log(item, this.diz);
        if (item.id == this.diz) {
          this.dizAddress = item.name;
        }
      });
       
      
    },
    clickSelect() {
        if(this.isEdit){
            return
        }
      if(!this.selectActive){
      this.$refs.myselect.$el.click();
      this.selectActive = true;
    }
    },
    getScan() {
      this.$http({
        url: "/caseInfo/getStepEightScan",
        method: "get",
        params: {
          caseid: this.$store.state.caseId + "",
          stageName: this.$store.state.stageNameId,
          // 病例次数
          stageCount: this.$store.state.stageCountId,
         
        },
      })
        .then(({ data }) => {
          data = JSON.parse(data.data);

          this.fileList = [];
          // console.log(data);
          data.forEach((item) => {
            console.log(item);
            if (item.filenumber == "12") {
              // console.log(item);
              this.fileList.push({
                name: item.filename,
                url: item.ossfilepath,
                id: item.casedataid,
              });
              this.flag = false;
              this.scwjadd = "N";
            }
          });
        })
        .catch((err) => {});
    },

    yjmxChange(val) {
      if (this.yjmx.indexOf(val) != -1) {
        this.yjmx.splice(this.yjmx.indexOf(val), 1);
      } else {
        this.yjmx.push(val);
      }
      if (this.yjmx.length == 0) {
        this.mailType = 0;
      }
    },
    setupFileList() {
      this.$emit("setupFileList", this.fileList);
    },
    lxjsjy() {
      this.dialogVisible5 = true;
    },
    getSFById(isflg) {
      this.$set(this.jjxl.jjdz, "address", "");
      this.$set(this.jjxl.jjdz, "contactNumber", "");
      this.$set(this.jjxl.jjdz, "deliveryName", "");
      this.$set(this.jjxl, "yyqj", "");

      this.yjmx = [];
      this.edit = 1;
      this.cancel = 1;
      this.state = "";

      let data = { caseIds: this.$store.state.caseId, mailingMethod: 1 }; //病例id

      this.$http({
        url: "/webCase/opGetSFById",
        method: "post",
        dataType: "json",
        data: data,
        contentType: "application/x-www-form-urlencoded",
      })
        .then(({ data }) => {
          this.isOpen = true;
        
          if (data.code == 200) {
            if (isflg) {
               this.$emit('dialogVisible3')
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 1500,
              });
            }

            let res = JSON.parse(data.data);
            console.log(res);
            if (res != null) {
              this.state = res.state;
              this.edit = res.edit;
              this.cancel = res.cancel;

              this.caseNoId = res.orderNo;
              let sj = "";
              /*  * 寄件地址：
                       中国上海上海闵行万金中心10F
  
                       海波13641636968
  
                       收件地址：
                       中环西路86号中科天保智谷9号楼
  
                       正丽科技质检部15890128745
  
                           * 预约取件：
                       今天(2022.12.21) 18: 00~19: 00 */

              if (res.mailingModel == 1) {
                this.yjmx = [1];
              } else if (res.mailingModel == 3) {
                this.yjmx = [1, 2];
              } else if (res.mailingModel == 2) {
                this.yjmx = [2];
              }
              console.log(this.yjmx);
              if (res.mailingMethod) {
                this.mailType = Number(res.mailingMethod);
              }
              if (res.orderState != "1") {
                let time = new Date();

                time = new Date(res.bookingTime);
                let time2 = new Date(time.getTime() + 1000 * 60 * 60);
                let jt = new Date();
                let Y = jt.getFullYear();
                let M = jt.getMonth() + 1;
                let D = jt.getDate();

                let mt = new Date(
                  new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                    24 * 3600 * 1000
                );
                let ht = new Date(
                  new Date(Y + "-" + M + "-" + D + " 00:00:00").getTime() +
                    48 * 3600 * 1000
                );
                let rq = "";
                console.log('xxx')

                  console.log(new Date().getDate(), new Date().getMonth())
                if (
                  time.getDate() == new Date().getDate() &&
                  time.getMonth() == new Date().getMonth()
                ) {
                  rq = "(今天)";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 1000 * 60 * 60);
                  } else {
                    time2 = new Date(time.getTime() + 1000 * 60 * 30);
                  }
                  sj =
                    `(${rq}) ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                  ${
                    this.addZero(time.getHours()) +
                    ":" +
                    time.getMinutes() +
                    ":" +
                    this.addZero(time.getSeconds())
                  } ~ 
                  ${
                    this.addZero(time2.getHours()) +
                    ":" +
                    time2.getMinutes() +
                    ":" +
                    this.addZero(time2.getSeconds())
                  } `;
                } else if (time.getDate() == mt.getDate() &&
                  time.getMonth() ==mt.getMonth()) {
                  rq = "(明天)";
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 25 * 1000 * 60 * 60);
                  } else {
                    time2 = new Date(
                      time.getTime() + 24 * 1000 * 60 * 60 + 60 * 1000 * 30
                    );
                  }
                  sj =
                    `(${rq}) ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                  ${
                    this.addZero(time.getHours()) +
                    ":" +
                    time.getMinutes() +
                    ":" +
                    this.addZero(time.getSeconds())
                  } ~ 
                  ${
                    this.addZero(time2.getHours()) +
                    ":" +
                    time2.getMinutes() +
                    ":" +
                    this.addZero(time2.getSeconds())
                  } `;
                } else {
                  if(time.getDate() == ht.getDate() &&
                  time.getMonth() == ht.getMonth()){
                  rq = "(后天)";
                }
                  if (time.getHours() < 20) {
                    time2 = new Date(time.getTime() + 49 * 1000 * 60 * 60);
                  } else {
                    time2 = new Date(
                      time.getTime() + 48 * 1000 * 60 * 60 + 60 * 1000 * 30
                    );
                }
                  }

                  sj =
                    `${rq} ` +
                    time.getFullYear() +
                    "-" +
                    this.addZero(time.getMonth() + 1) +
                    "-" +
                    this.addZero(time.getDate()) +
                    ` 
                  ${
                    this.addZero(time.getHours()) +
                    ":" +
                  this.addZero( time.getMinutes()) +
                    ":" +
                    this.addZero(time.getSeconds())
                  } ~ 
                  ${
                    this.addZero(time2.getHours()) +
                    ":" +
                  this.addZero(time2.getMinutes()) +
                    ":" +
                    this.addZero(time2.getSeconds())
                  } `;

                // }
                // this.$set( this.jjxl,'yyqj',  '')
                this.empPhone = res.empPhone;
                this.takeNo = res.takeNo;
                   //   console.log(this.dizshuzu);
                this.dizshuzu.filter((item) => {
               
                  if (
                    item.id == res.addressId ||
                    item.id == res.sendAddressIdWeb
                  ) {
                    this.$set(
                      this.jjxl.jjdz,
                      "address",
                      item.country + item.province + item.city + item.area + item.address
                    );
					this.dizAddress=this.jjxl.jjdz.address
                    this.$set(this.jjxl.jjdz, "contactNumber", item.contactNumber);
                    this.$set(this.jjxl.jjdz, "deliveryName", item.deliveryName);
				  console.log('取件时间',sj);
                    this.$set(this.jjxl, "yyqj", sj);
					this.qjsj.txt=sj;
					this.qjsj.time=time.getFullYear()+'-'+this.addZero(time.getMonth()+1)+'-'+this.addZero(time.getDate())+' '+this.addZero(time.getHours())+':'+this.addZero(time.getMinutes())+':'+this.addZero(time.getSeconds());
                  }
                });
              }
            }
          }
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
    xgdj() {
     
    
    },
    //收件地址
    getSjdz() {
      this.$http({
        url: "/webCase/optoAddress",
        method: "post",
      })
        .then(({ data }) => {
          this.isOpen = true;

          if (data.code == 200) {
            let res = JSON.parse(data.data);
            // console.log(res);
            this.jjxl.sjdz.address = res.address;
            this.jjxl.sjdz.city = res.city;
            this.jjxl.sjdz.province = res.province;
            this.jjxl.sjdz.area = res.county;
            this.jjxl.sjdz.contactNumber = res.tel;
            this.jjxl.sjdz.deliveryName = res.contact;
            // console.log(this.jjxl.sjdz);
          }
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
    qxjj() {
      if (this.isOpen) {
        this.isOpen = false;
        this.$confirm("你是否要取消寄件", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http({
              url: "/webCase/opCancelOrder",
              method: "post",
              data: {
                dealType: 2,
                language: "zh_CN",
                orderId: this.caseNoId,
              },
            })
              .then(({ data }) => {
                this.isOpen = true;

                if (data.code == 200) {
                  this.getSFById();
                  this.$message({
                    message: "取消寄件成功",
                    type: "success",
                    duration: 1500,
                  });
                }
              })
              .catch((err) => {
                this.isOpen = true;

                console.log(err);
              });
          })
          .catch((err) => {
            this.isOpen = true;
            return;
          });
      }
    },

    checkHours(val) {
      console.log(val);
      this.hoursList = this.hoursList.filter((item) => {
        if (val.id == item.id) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return true;
      });
      this.qjsj.txt =
        val.txt +
        " (" +
        val.time +
        ") " +
        (val.id == 0 ? "一小时内" : val.start + "~" + val.end);
      this.qjsj.dayId = val.dayId;
      this.qjsj.hoursId = val.id;
      val.id == 0
        ? (this.qjsj.time =
            val.formatTime +
            ` ${
              new Date().getHours() < 10
                ? "0" + new Date().getHours()
                : new Date().getHours()
            }:` +
            `${
              new Date().getMinutes() < 10
                ? "0" + new Date().getMinutes()
                : new Date().getMinutes()
            }:` +
            `${
              new Date().getSeconds() < 10
                ? "0" + new Date().getSeconds()
                : new Date().getSeconds()
            }`)
        : (this.qjsj.time = val.formatTime + " " + val.start + ":00");

      this.isQjsj = false;
    },
    checkDay(val) {
      this.jt.checked = false;
      this.mt.checked = false;
      this.ht.checked = false;
      val.checked = true;

      this.hoursList = [];
      if (val.h) {
        var h = new Date().getHours();
        this.nowH = h;
        h < 8 ? (h = 8) : h;
        var m = new Date().getMinutes();
        if (h > 20 || (h == 20 && m > 30)) {
          return;
        }
        console.log(val);
        this.hoursList.push({
          dayId: val.id,
          id: 0,
          txt: val.txt,
          time: val.time,
          formatTime: val.formatTime,
          checked: false,
        });
        for (var i = h; i < 20; i++) {
          this.hoursList.push({
            dayId: val.id,
            id: i,
            time: val.time,
            formatTime: val.formatTime,
            txt: val.txt,
            checked: false,
            start: (i < 10 ? "0" + i : i) + ":00",
            end: (i + 1 < 10 ? "0" + (i + 1) : i + 1) + ":00",
          });
        }
        this.hoursList.push({
          dayId: val.id,
          id: i,
          time: val.time,
          formatTime: val.formatTime,
          txt: val.txt,
          checked: false,
          start: (i < 10 ? "0" + i : i) + ":00",
          end: "20:30",
        });
      } else {
        for (var i = 8; i < 20; i++) {
          this.hoursList.push({
            dayId: val.id,
            id: i,
            time: val.time,
            formatTime: val.formatTime,
            txt: val.txt,
            checked: false,
            start: (i < 10 ? "0" + i : i) + ":00",
            end: (i + 1 < 10 ? "0" + (i + 1) : i + 1) + ":00",
          });
        }
        this.hoursList.push({
          dayId: val.id,
          id: i,
          time: val.time,
          formatTime: val.formatTime,
          txt: val.txt,
          checked: false,
          start: (i < 10 ? "0" + i : i) + ":00",
          end: "20:30",
        });
      }
    },
    timeFormat() {
      let time = new Date();
      let y = time.getFullYear();
      let m = this.addZero(time.getMonth() + 1);
      let d = this.addZero(time.getDate());
      let h = time.getHours();
      this.jt.txt = "今天";
      this.jt.time = `${y}.${m}.${d}`;
      this.jt.formatTime = `${y}-${m}-${d}`;
      this.jt.h = h;
      time = new Date(new Date().getTime() + 3600000 * 24);
      y = time.getFullYear();
      m = this.addZero(time.getMonth() + 1);
      d = this.addZero(time.getDate());
      h = time.getHours();
      this.mt.txt = "明天";
      this.mt.time = ` ${y}.${m}.${d}`;
      this.mt.formatTime = `${y}-${m}-${d}`;
      time = new Date(new Date().getTime() + 3600000 * 24 * 2);
      y = time.getFullYear();
      m = this.addZero(time.getMonth() + 1);
      d = this.addZero(time.getDate());
      h = time.getHours();
      this.ht.txt = "后天";

      this.ht.time = ` ${y}.${m}.${d}`;
      this.ht.formatTime = `${y}-${m}-${d}`;
    },
    addZero(val) {
      if (val < 10) {
        return "0" + val;
      } else {
        return val;
      }
    },
    copyAddress() {
      let copyInput = document.createElement("input"); //创建input元素
      document.body.appendChild(copyInput); //向页面底部追加输入框
      copyInput.setAttribute(
        "value",
        this.jjxl.sjdz.province +
          this.jjxl.sjdz.city +
          this.jjxl.sjdz.area +
          this.jjxl.sjdz.address +
          this.jjxl.sjdz.deliveryName +
          this.jjxl.sjdz.contactNumber
      ); //添加属性，将url赋值给input元素的value属性
      copyInput.select(); //选择input元素
      document.execCommand("Copy"); //执行复制命令
      this.$message.success("地址复制成功"); //弹出提示信息，不同组件可能存在写法不同
      //复制之后再删除元素，否则无法成功赋值
      copyInput.remove(); //删除动态创建的节点
    },
    eidtAddres() {
      this.$refs.tanchuangdizhi.dialogFormVisibledizhi = true;
    },
    // 新增地址方法
    pushdizhishuju() {
      this.dizhijieko();
      // this.form.diz =
      // this.$refs.tanchuangdizhi.country +
      // this.$refs.tanchuangdizhi.province +
      // this.$refs.tanchuangdizhi.city +
      // this.$refs.tanchuangdizhi.area +
      // this.$refs.tanchuangdizhi.form.diz;
      // this.dizshuzu.push(this.form.diz)
    },
    // 收货收获地址列表
    dizhijieko() {
        this.$http({
          url: "/deliveryAddress/getAddressList",
          method: "get",
          params: {},
        })
          .then(({ data }) => {
            if (data.code == 200) {
              this.dizshuzu = JSON.parse(data.data).map((x) => {
                return {
                  name:
                    x.country +
                    x.province +
                    x.city +
                    x.area +
                    x.address +
                    " - " +
                    x.deliveryName,
                  id: x.id.toString(),
                  country: x.country,
                  province: x.province,
                  city: x.city,
                  area: x.area,
                  address: x.address,
                  deliveryName: x.deliveryName,
                  contactNumber: x.contactNumber,
                  orthoPlusId: x.orthoPlusId,
                  del: x.del,
                };
              });
                if(this.diz){
                     this.dizshuzu.filter(item=>{
                        if(item.id==this.diz){
                            this.dizAddress=item.name
                        }
                     })
                }
            // console.log(this.dizshuzu);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    upFile() {
      this.dialogVisible = true;
    },
    submit(val, isEdit) {
      if (val == 1) {
        this.dialogVisible = false;
        this.dialogVisible2 = true;
      } else if (val == 3 && !isEdit) {
        this.orders();
      } else if (val == 3 && isEdit) {
        this.upOrder();
      } else {
        this.dialogVisible2 = false;
      }
    },
    upOrder() {
       console.log(this.qjsj);
      let time = this.qjsj.time.split(" ");

      let params = {
        cancel: false,
        deliverDate: time[0],
        deliverTimeMin: time[1],
        waybillNo: this.takeNo,
        serviceCode: 7,
      };

      this.$http({
        url: "/webCase/EditOrders",
        method: "POST",
        data: params,
      })
        .then(({ data }) => {
          this.$message({ message: "修改成功", type: "success", duration: 1500 });
        this.$emit('dialogVisible3')

          this.getSFById();
        })
        .catch((err) => {
          this.isOpen = true;

          console.log(err);
        });
    },
    //下单
    orders(val) {
      let params = {};
      val == 2 ? (this.mailType = 2) : "";
      if (!this.diz && val != 2) {
        this.$message({ message: "请填写寄件地址", type: "error", duration: 1500 });
        return;
      }
      if (!this.qjsj.txt && val != 2) {
        this.$message({ message: "请选择预约取件时间", type: "error", duration: 1500 });
        return;
      }
      if (this.isOpen) {
        this.isOpen = false;
        let address = this.dizshuzu.filter((item) => {
          if (item.id == this.diz) {
            return item;
          }
        })[0];

        let time = new Date();
        let mailingModel = 0;
        this.yjmx.filter((item) => {
          mailingModel += item;
        });
        if (val != 2) {
          params = {
            caseId: this.$store.state.caseId, //病例id
            caseNo: this.erpcaseno, //病例编号
            sendAddressIdWeb: address.id, //地址id
            // recv_address:address.address,//收件人地址
            sendAddressIdOrtho: address.orthoPlusId, // orthID
            mailingModel: mailingModel,
            mailingMethod: Number(this.mailType),

            orderId:
              this.erpcaseno +
              time.getFullYear() +
              (time.getMonth() + 1) +
              time.getDate() +
              time.getHours() +
              time.getMinutes() +
              time.getSeconds(), //病例编号
              phone:address.contactNumber,
              contactInfoList: [
              {
                address: address.address,
                city: address.city,
                county: address.area,
                province: address.province,

                contact: address.deliveryName,
                contactType: 1,
                country: "CN",
                tel: address.contactNumber,
              },
            ],
            expressTypeId: 2, //标准快速
            sendStartTm: this.qjsj.time, // 取件时间
          };
        } else {
          if (this.caseNoId) {
            this.$http({
              url: "/webCase/opCancelOrder",
              method: "post",
              data: {
                dealType: 2,
                language: "zh_CN",
                orderId: this.caseNoId,
              },
            })
              .then(({ data }) => {
                if (data.code == 200) {
                 
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          params = {
            caseId: this.$store.state.caseId, //病例id
            caseNo: this.erpcaseno, //病例编号
            mailingModel: mailingModel,
            mailingMethod: 2,
          };
        }
        this.$http({
          url: "/webCase/opCallSFOrder",
          method: "POST",
          data: params,
        })
          .then(({ data }) => {
            this.isOpen = true;

            this.jjxl.yyqj = this.qjsj.txt;
            this.caseNoId = params.orderId;
            this.LoadingOpen();
            if (val != 2) {
              setTimeout(() => {
                this.getSFById(true);
              }, 3000);
            } else {
              this.getSFById();
            }
          })
          .catch((err) => {
            this.isOpen = true;

            console.log(err);
          });
      }
    },
    canel(val) {
      val == 1 ? (this.dialogVisible = false) : (this.dialogVisible2 = false);
      switch (val) {
        case 1:
          this.dialogVisible = false;
          break;
        case 2:
          this.dialogVisible2 = false;
          break;
        case 3:
          this.$emit('dialogVisible3')
        
          break;
      }
    },
		}
	};
</script>
<style lang="less" scoped>
/deep/ .el-dialog{
    padding-left: 0 !important;
}
	@media screen and (max-width: 1660px) {
		#particulars-schedule .el-timeline-item[data-v-0b4bed50] .butonezu .el-button {
			transform: scale(0.8);
			margin: 0px;
		}

		#particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu .el-button {
			transform: scale(0.8);
			margin: 0px;
			width: 140px;
			float: left;
			display: block;
		}

		#particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu {
			width: 140px !important;
			// bottom: -36% !important;
			right: 2% !important;
		}
/deep/ .el-dialog{
			margin-top: 18vh !important;
			transform: scale(0.9);
			height: 520px !important;
      padding-left:0 !important;
		}
		#particulars-schedule .el-dialog  .el-dialog__body .koko{
			margin-bottom: 20px !important;
		}
		
		#particulars-schedule .el-dialog  .el-dialog__body .wrap{
			height: 300px !important;
		}
	}


	@media screen and (max-width: 1360px) {
 /deep/ .el-dialog{
			transform: scale(0.8);
			height: 520px !important;
		}
		#particulars-schedule .el-dialog  .el-dialog__body .koko{
			margin-bottom: 20px !important;
		}
		
		#particulars-schedule .el-dialog  .el-dialog__body .wrap{
			height: 300px !important;
		}
	}
</style>
<style scoped lang="less">
#particulars-schedule /deep/ .el-dialog{
  height:520px !important;
}
.btn-box{
	display: flex;
	  align-items: center;
	
	  
	 &>div{
		display: flex;
	  align-items: center;
	  color:#1275D2;
	  font-size: 12px;
		  margin-right: 25px;
	  cursor: pointer;
		} 
	  img{
		width: 13px;
		margin-right: 7px;
	  }
}
.address{
	  width: 100%;
	  overflow: hidden;
	  text-overflow: ellipsis;
}
.show-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;


  .logistics {
    width: 400px;
    height: 592px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);

    .title {
      height: 32px;
      background: #343b43;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      padding: 0 20px;
      margin-bottom: 16px;
      span:nth-of-type(2)::after {
        content: "X";
        cursor: pointer;
      }
    }
    .content {
      padding-left: 20px;
    }
    .item {
      display: flex;

      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      p:nth-of-type(1) {
        width: 64px;
      }
      p:nth-of-type(2) {
        width: 180px;
      }

      .icon-img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .btn {
      margin-top: 12px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1275d2;
      padding-bottom: 8px;

      border-bottom: #1275d2 solid 1px;
      & > div {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #1275d2;
        margin-right: 26px;
        img {
          margin-right: 2px;
        }
      }
    }
    .list {
		  height: 415px;
		   overflow: auto;
      .last-box {
        &::before {
          content: "";
           position: absolute;
           z-index: 99;
           left: 0;
          width: 10px;
          height: 10px;
          background: #bababa !important;
          border-radius: 50%;
        }
      }
      & > div {
        display: flex;
        
        & > div:nth-of-type(1) {
          width: 80px;
        
          font-size: 12px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #666666;
        }
        & > div:nth-of-type(2) {

			width: 230px;
			padding-bottom: 20px;
          display: flex;
          font-size: 14px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;
        
          position: relative;
          overflow: hidden;
          padding-left: 20px;
          .first {
          
          }
       
          .line {
            position: absolute;
            left: 5px;

            border-left: 1px dashed #0089ff;
		
            height: 100%;
          }
          &::before {
            position: absolute;
         left: 0;
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: #0089ff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.planFlag {
		/deep/.el-button {
			background: #666 !important;
			border: none !important;
			span {
				color: #fff !important;
			}
		}
	}
a {
	text-decoration:none;
	color: #fda21c;
	&:hover {
		color: #333;
	}
}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //针对ie
		#particulars-schedule .el-dialog .el-dialog__body .wrap .el-timeline-item__wrapper{
			top:-7px;
		}
	}
	#particulars-schedule {
		overflow: auto;

		.el-timeline-item {
			height: 70px;
			padding-bottom: 34px;

			.el-timeline-item__dot {
				.el-image {
					margin-left: -8px;
					width: 26px;
					height: 26px;
				}
			}

			/deep/.el-timeline-item__wrapper {
				position: relative;
				padding-left: 36px;
				font-size: 16px;
			}

			/deep/ .el-timeline-item__tail {
				border-left-style: dashed;
			}

			.line {
				height: 0;
				width: 94%;
				position: absolute;
				bottom: -34px;
				right: 0;
				border: 0.5px solid #d6d6d6;
			}

			/deep/.buttwozu {
				// height:36px;
				// width:100%;
				position: absolute;
				right: 2%;
				bottom: 0%;
				z-index: 1;

				.el-button {
					width: 140px;
					height: 36px;
					padding: 0;
					line-height: 36px;
					border: 1px solid #f99400;
					box-sizing: border-box;
					background: #f9f9f9;

					span {
						color: #fda21c;
					}

					// span:hover {
					// 	color: #fff;
					// }
				}

				// .el-button:hover {
				//   background-color: red;
				// }

				.el-button:active {
					// background-color:#1175d2;
				}
			}

			.butonezu {
				// height:36px;
				// width:100%;
				position: absolute;
				right: 2%;
				// margin-top:-15%;
				bottom: 0%;
				z-index: 1;

				/deep/.el-button {
					width: 140px;
					height: 36px;
					padding: 0;
					line-height: 36px;
					background: #f99400;
					border: none;

					span {
						color: #fff;
					}
				}
			}

			.huyi2:hover {
				background-color: #fda21c !important;
			}

			// .line:last-child{
			//     border:none;
			// }
		}

		/deep/ .el-dialog {
			width: 570px;
			height: 610px;
			// margin-top: 18vh !important;
			background: #f9f9f9;
			

			.el-dialog__header {
				padding: 25px 25px 0 40px;

				 .el-dialog__title {
					font-weight: normal;
					padding: 0;
				}

				.el-dialog__headerbtn {
					top: 15px;
					right: 17px;
				}

				.el-dialog__headerbtn .el-dialog__close {
					width: 14px;
					height: 14px;
					color: #4b4b4b;

				}
			}

			.el-dialog__body {
				padding: 0;
				text-align: center;
				font-size: 16px;

				.trackingNumber {
					text-align: left;
					margin-bottom:10px;
				}
				
				.koko {
					overflow: hidden;
					margin-bottom: 48px;

					.p1 {
						margin-right: 60px;
					}
				}

				.wrap {
					height: 420px;
					padding-right: 120px;
					overflow: auto;
					.el-timeline-item{
						height: auto;
					}
					.el-timeline{
						padding-top:10px ;
					}
					.el-timeline-item__wrapper{
						width: 70%;
						padding-left: 168px;
						top:-12px;
					}
					.el-timeline-item__timestamp{
						
					}
					.el-timeline-item__tail{
						height:100%;
						border-left-style: solid;
						border-left-color: #999999;
						left: 129px;
						// top:22px;
					}
					.el-timeline-item__node--normal{
						left: 120px;
						width: 20px;
						height: 20px;
					}
					.el-timeline-item__content,.el-timeline-item__timestamp{
						text-align: left;
					}
					.el-timeline-item__timestamp.is-bottom{
						margin-top: 5px;
						color: #666 !important;
					}
					.time{
						position: absolute;
						left: 0;
						top:0;
						font-size: 16px;
						
					}
					.date{
							color: #333 !important;
					}
					.specificTime{
						color: #666 !important;
					}
				}
			}


		}
	}
</style>

<style scoped lang="less">
	@media screen and (max-width: 1660px) {
		#particulars-schedule .el-timeline-item .butonezu .el-button {
			transform: scale(0.8) !important;
			margin: 0px !important;
		}

		#particulars-schedule .el-timeline-item .buttwozu .el-button {
			transform: scale(0.8) !important;
			margin: 0px !important;
			width: 140px !important;
			float: left !important;
		}

		#particulars-schedule .el-timeline-item .buttwozu {
			width: 140px !important;
			// bottom: -36% !important;
			right: 2% !important;
		}
	}

	@media screen and (max-width: 1360px) {}
</style>
<style lang="less" scoped>
.el-timeline .el-timeline-item {
	position: relative;
}
.reminder {
	position: absolute;
	top: 0;
	left: 165px;
	width: 260px;
	height: 80px;
	padding: 15px;
	border-radius: 4px;
	box-shadow: 2px 2px 5px #aaa;
	justify-content: space-between;
	box-sizing: border-box;
	background: #fff;
	z-index: 5;
	.top,.bottom {
		font-size: 14px;
		color: #333;
		i {
			font-style: normal;
		}
		span:nth-of-type(1){
			color: #1175d2;
		}
		span:nth-of-type(2){
			margin-right: 26px;
		}
	}
	.bottom {
		margin-top: 10px;
		span:nth-of-type(1){
			margin-right: 13px;
		}
		span:nth-of-type(2){
			margin-right: 34px;
		}
	}
}

.reminderStyle {
	position: absolute;
	top: 3px;
	left: 138px;
	width: 20px;
	height: 20px;
}
</style>
<style scoped lang="less">
.footer {
  margin-top: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.space {
  margin-right: 10px;
}

.qjxl {
  .title {
    margin-top: 20px;
    margin-bottom: 16px;
  }

  span,
  .title {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ff0000;
  }

  .jjdz {
    display: flex;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    margin-bottom: 16px;

    .space {
      margin-left: 10px;
    }

    & > div:first-of-type {
      width: 90px;
    }
  }

  .order-btn {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;

    .active {
      color: #1275d2;
      text-decoration: underline;
    }
    & > div {
      width: auto !important;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.qjsj {
  padding-left: 12px;
  width: 300px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #1275d2;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
    //transform: translateY(-50%);
  }

  .qjsj-box {
    left: 0;
    width: 560px;
    height: 160px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    position: absolute;
    background: #ffffff;
    top: 42px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .qjsj-day {
      padding: 16px 0;
      display: flex;
      justify-content: space-around;
      align-items: center;

      div {
        cursor: pointer;
        width: 160px;
        height: 28px;
        font-size: 14px;
        border: 1px solid #1275d2;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .active {
        background: #1275d2;
        color: #ffffff;
      }
    }

    .qjsj-line {
      margin: auto;
      width: 540px;
      height: 1px;
      background: #999999;
    }

    .qjsj-hour {
      margin-right: -18px;
      flex-shrink: 1;
      overflow-y: scroll;
      display: flex;
      padding-left: 16px;
      align-items: center;
      flex-wrap: wrap;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-top: 16px;
        margin-bottom: 16px;
        margin-right: 18px;
        width: 90px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid #1275d2;
        display: flex;
        justify-items: center;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #333333;

        p {
          margin-top: 0;
        }
      }

      div:nth-of-type(5n) {
        margin-right: 0;
      }

      .active {
        background: #1275d2;
        color: #ffffff;
      }
    }
  }
}

.close::after {
  top: 19%;
  border: 8px solid transparent;
  border-bottom: 8px solid #1275d2;
}

.click-select {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 10px;
  padding-right: 35px;
  background: white;
  z-index: 10;
  width: 300px;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #1275d2;
  &.active::after {
    top: 19%;
    border: 8px solid transparent;
    border-bottom: 8px solid #1275d2;
  }
  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
  }
}
/deep/ .express {
  height: 448px;

  .el-input__inner {
    margin-left: 1px;
    margin-top: 1px;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: transparent;
    color: transparent;
  }

  .edit-box {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }

  .code {
    margin-top: 32px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #0075f6;
	text-align: left;
  }

  .detail {
    align-self: flex-start;
    margin-top: 32px;
    margin-left: 0;
  }

  .text {
	text-align: left;
    margin-top: 2px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
  }
}

.copy-icon {
  cursor: pointer;
  align-self: end;
}

.address {
  margin-top: 32px;
  display: flex;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
}

/deep/ .tangcform {
  padding-top: 20px;
}

.blue-btn {
  cursor: pointer;
  width: 96px;
  height: 28px;
  background: #1275d2;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
}

.img-box {
  display: inline-block;
  height: 14px;
  width: 14px;
  margin-right: 9px;

  img {
    width: 100%;
    display: block;
  }
}

.table {
  /deep/ th {
    border-bottom: #1275d2 2px solid;
  }

  /deep/ td {
    cursor: pointer;
  }

  // 修改鼠标经过表格的颜色
  /deep/ .el-table tbody tr:hover > td {
    // 可以选择隐藏
    background-color: transparent !important;
    opacity: 0.8;
  }
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog__header {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}

/deep/ .el-dialog__title {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  line-height: 32px;
  padding: 0;
}

/deep/.el-dialog__headerbtn {
  top: 8px;
}

/deep/.el-dialog__header {
  height: 32px;
  background: #1275d2;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.dialog-div {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.canel {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #0075f6;
}

.submit {
  cursor: pointer;
  margin-right: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 24px;
  background: #1275d2;
  border-radius: 4px;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
}


.dialog-box {
  padding-left: 24px;

  p {
    margin-top: 8px;
  }

  .qjsj {
    /deep/.el-input__inner {
      margin-top: 8px;
      width: 400px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #1275d2;
    }
  }

  /deep/ .el-icon-arrow-up:before {
    color: #1275d2;
  }
}

.div-text {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
  margin-top: 32px;
}

.search-box {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-left: 24px;
  padding-right: 15px;
  padding-top: 32px;
  padding-bottom: 36px;

  /deep/ .el-input {
    width: auto;
  }

  /deep/.el-input__inner {
    margin-top: 0;
    width: 240px;
    height: 32px;
    background: #ffffff;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
  }

  /deep/ .el-input__icon {
    line-height: 32px;
  }

  .btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 32px;
    background: #1275d2;
    border-radius: 4px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #ffffff;
  }
}

.del {
  cursor: pointer;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ff0000;
  line-height: 20px;
  text-decoration: underline;
}

.xuanze {
  cursor: pointer;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #1275d2;
  line-height: 20px;
  text-decoration: underline;
}

.table-box {
  width: 772px;
  margin-left: 76px;
  margin-top: 40px;

  /deep/ .el-table {
    background: transparent;

    .el-table__header {
      background: transparent;
    }

    th {
      border-bottom: 2px #1a95eb solid;
    }

    th,
    tr {
      background-color: transparent;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 20px;
    }
  }
}

.blue-bg {
  background-color: #1275d2;
  color: #ffffff !important;
}

.detail-address {
  margin-left: 20px;
}

.detail {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  margin-left: 76px;
  display: flex;
  align-items: center;

  span {
    color: #ff0000;
  }
}

.radio-div {
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;

  div {
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
    cursor: pointer;
    margin-right: 32px;
    padding: 0 20px;
    height: 28px;
    border-radius: 16px;
    border: 1px solid #1275d2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.radio-box {
  margin-top: 42px;
  margin-left: 76px;
  margin-bottom: 40px;
}

/deep/.upload-demo {
  c .el-upload-list {
    position: absolute;
    left: 50%;
    top: 225px;
    transform: translateX(-50%);
    width: 200px;

    .el-upload-list__item-status-label,
    .el-icon-close {
      top: -238px;
      right: -202px;
      opacity: 0;
      display: block;
    }

    .el-upload-list__item-status-label {
      .el-icon-upload-success {
        width: 604px;
        height: 300px;
      }
    }

    .el-icon-close {
      width: 604px;
      height: 300px;
    }
  }
}

.scwj {
  height: 550px;
  position: relative;
}

.wenjscbox {
  position: relative;

  left: 76px;

  .upFile {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .fileName {
      max-width: 160px;
      padding-right: 10px;
      height: 20px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .btn {
      cursor: pointer;
      color: red;
    }
  }
}

.wenjscbox {
  width: 600px;

  img {
    width: 26px;
    height: 26px;
    margin-right: 8px;
  }

  .btn {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    line-height: 20px;
  }
}

.show-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0e, 0.5);
  z-index: 9999;
  top: 0;
  left: 0;

  & > div {
    position: absolute;
    background: #f9f9f9;
    width: 500px;
    height: 200px;
    left: 50%;
    top: 50%;

    div {
      margin-top: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      p {
        display: inline-block;
        max-width: 150px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    transform: translate(-50%, -50%);

    .top {
      margin-top: 0;
      height: 20px;
      background: #333333;
    }

    .footer {
      position: absolute;
      bottom: 20px;
      display: flex;
      left: 50%;
      transform: translateX(-50%);

      & > div {
        margin-right: 50px;
        background: #1175d2;
        border-radius: 5px;
        padding: 5px;
        color: #f9f9f9;
        white-space: nowrap;
        cursor: pointer;
      }
    }
  }
}
.disabled {
  color: #666666;
}
.my-select {
  /deep/ .el-select-dropdown__list {
    padding-bottom: 20px;
  }
}
/deep/ .el-radio__inner{
    width: 20px;
    height: 20px;
} 
/deep/ .el-radio__input.is-checked .el-radio__inner{
		 background: #fff !important;
		  width: 20px;
      height: 20px;
     &::after{
			width: 10px;
height: 10px;
background: #1175D2;
border-radius: 50%;
		 }
	}
  .jkd{
    /deep/ .el-dialog__close{
       color: #ffffff;
    }
    /deep/.el-dialog__body{
        overflow: inherit;
    }
  }
</style>
<style lang="less" scoped>
	@media screen and (max-width: 1660px) {
		#particulars-schedule .el-timeline-item[data-v-0b4bed50] .butonezu .el-button {
			transform: scale(0.8);
			margin: 0px;
		}

		#particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu .el-button {
			transform: scale(0.8);
			margin: 0px;
			width: 140px;
			float: left;
			display: block;
		}

		#particulars-schedule .el-timeline-item[data-v-0b4bed50] .buttwozu {
			width: 140px !important;
			// bottom: -36% !important;
			right: 2% !important;
		}
		#particulars-schedule /deep/ .el-dialog{
			margin-top: 18vh !important;
			transform: scale(0.9);
			height: 475px !important;
		}
		#particulars-schedule .el-dialog  .el-dialog__body .koko{
			margin-bottom: 20px !important;
		}
		
		#particulars-schedule .el-dialog  .el-dialog__body .wrap{
			height: 300px !important;
		}
	}

	@media screen and (max-width: 1360px) {
		#particulars-schedule /deep/ .el-dialog{
			transform: scale(0.8);
			height: 520px !important;
		}
		#particulars-schedule .el-dialog  .el-dialog__body .koko{
			margin-bottom: 20px !important;
		}
		
		#particulars-schedule .el-dialog  .el-dialog__body .wrap{
			height: 300px !important;
		}
	}
</style>
<style scoped lang="less">

.dialog-box {
  padding-left: 24px;

  p {
    margin-top: 8px;
  }

  .qjsj {
    /deep/.el-input__inner {
      margin-top: 8px;
      width: 400px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #1275d2;
    }
  }

  /deep/ .el-icon-arrow-up:before {
    color: #1275d2;
  }
}

.detail {
    align-self: flex-start;
    margin-top: 32px;
    margin-left: 0;
  }
  .click-select {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 10px;
  padding-right: 35px;
  background: white;
  z-index: 10;
  width: 300px;
  height: 64px;
  border-radius: 6px;
  border: 1px solid #1275d2;
  &.active::after {
    top: 19%;
    border: 8px solid transparent;
    border-bottom: 8px solid #1275d2;
  }
  &::after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
    border-top: 8px solid #1275d2;
    right: 9px;
    top: 40%;
  }
}
/deep/ .express {
  height: 448px;

  .el-input__inner {
    margin-left: 1px;
    margin-top: 1px;
    width: 300px;
    height: 64px;
    border-radius: 6px;
    border: transparent;
    color: transparent;
  }

  .edit-box {
    cursor: pointer;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #1275d2;
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }

  .code {
    margin-top: 32px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #0075f6;
  }
  .my-select {
    /deep/ .el-select-dropdown__list {
      padding-bottom: 20px;
    }
}

  .detail {
    align-self: flex-start;
    margin-top: 32px;
    margin-left: 0;
  }

  .text {
    margin-top: 2px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #666666;
  }
}
.code {
    margin-top: 32px;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #0075f6;
  }
.div-text {
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #666666;
  line-height: 20px;
  margin-top: 32px;
}
  .jkd{
    /deep/ .el-dialog__close{
       color: #ffffff;
    }
    /deep/.el-dialog__body{
        overflow: inherit;
    }
  }
.btn-box{
	display: flex;
	  align-items: center;
	
	  
	 &>div{
		display: flex;
	  align-items: center;
	  color:#1275D2;
	  font-size: 12px;
		  margin-right: 25px;
	  cursor: pointer;
		} 
	  img{
		width: 13px;
		margin-right: 7px;
	  }
}
.address{
	  width: 100%;
	  overflow: hidden;
	  text-overflow: ellipsis;
}
.show-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;


  .logistics {
    width: 400px;
    height: 592px;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);

    .title {
      height: 32px;
      background: #343b43;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      padding: 0 20px;
      margin-bottom: 16px;
      span:nth-of-type(2)::after {
        content: "X";
        cursor: pointer;
      }
    }
    .content {
      padding-left: 20px;
    }
    .item {
      display: flex;

      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      p:nth-of-type(1) {
        width: 64px;
      }
      p:nth-of-type(2) {
        width: 180px;
      }

      .icon-img {
        width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
    .btn {
      margin-top: 12px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #1275d2;
      padding-bottom: 8px;

      border-bottom: #1275d2 solid 1px;
      & > div {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #1275d2;
        margin-right: 26px;
        img {
          margin-right: 2px;
        }
      }
    }
    .list {
		  height: 415px;
		   overflow: auto;
      .last-box {
        &::before {
          content: "";
           position: absolute;
           z-index: 99;
           left: 0;
          width: 10px;
          height: 10px;
          background: #bababa !important;
          border-radius: 50%;
        }
      }
      & > div {
        display: flex;
        
        & > div:nth-of-type(1) {
          width: 80px;
        
          font-size: 12px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #666666;
        }
        & > div:nth-of-type(2) {

			width: 230px;
			padding-bottom: 20px;
          display: flex;
          font-size: 14px;
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #333333;
        
          position: relative;
          overflow: hidden;
          padding-left: 20px;
          .first {
          
          }
       
          .line {
            position: absolute;
            left: 5px;

            border-left: 1px dashed #0089ff;
		
            height: 100%;
          }
          &::before {
            position: absolute;
         left: 0;
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: #0089ff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.planFlag {
		/deep/.el-button {
			background: #666 !important;
			border: none !important;
			span {
				color: #fff !important;
			}
		}
	}
a {
	text-decoration:none;
	color: #fda21c;
	&:hover {
		color: #333;
	}
}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { //针对ie
		#particulars-schedule .el-dialog .el-dialog__body .wrap .el-timeline-item__wrapper{
			top:-7px;
		}
	}
	#particulars-schedule {
		overflow: auto;

		.el-timeline-item {
			height: 70px;
			padding-bottom: 34px;

			.el-timeline-item__dot {
				.el-image {
					margin-left: -8px;
					width: 26px;
					height: 26px;
				}
			}

			/deep/.el-timeline-item__wrapper {
				position: relative;
				padding-left: 36px;
				font-size: 16px;
			}

			/deep/ .el-timeline-item__tail {
				border-left-style: dashed;
			}

			.line {
				height: 0;
				width: 94%;
				position: absolute;
				bottom: -34px;
				right: 0;
				border: 0.5px solid #d6d6d6;
			}

			/deep/.buttwozu {
				// height:36px;
				// width:100%;
				position: absolute;
				right: 2%;
				bottom: 0%;
				z-index: 1;

				.el-button {
					width: 140px;
					height: 36px;
					padding: 0;
					line-height: 36px;
					border: 1px solid #f99400;
					box-sizing: border-box;
					background: #f9f9f9;

					span {
						color: #fda21c;
					}

					// span:hover {
					// 	color: #fff;
					// }
				}

				// .el-button:hover {
				//   background-color: red;
				// }

				.el-button:active {
					// background-color:#1175d2;
				}
			}

			.butonezu {
				// height:36px;
				// width:100%;
				position: absolute;
				right: 2%;
				// margin-top:-15%;
				bottom: 0%;
				z-index: 1;

				/deep/.el-button {
					width: 140px;
					height: 36px;
					padding: 0;
					line-height: 36px;
					background: #f99400;
					border: none;

					span {
						color: #fff;
					}
				}
			}

			.huyi2:hover {
				background-color: #fda21c !important;
			}

			// .line:last-child{
			//     border:none;
			// }
		}

		/deep/ .el-dialog {
			width: 570px;
			height: 610px;
			// margin-top: 18vh !important;
			background: #f9f9f9;
		

			.el-dialog__header {
				padding: 25px 25px 0 40px;

				 .el-dialog__title {
					font-weight: normal;
					padding: 0;
				}

				.el-dialog__headerbtn {
					top: 15px;
					right: 17px;
				}

				.el-dialog__headerbtn .el-dialog__close {
					width: 14px;
					height: 14px;
					color: #4b4b4b;

				}
			}

			.el-dialog__body {
				padding: 0;
				text-align: center;
				font-size: 16px;

				.trackingNumber {
					text-align: left;
					margin-bottom:10px;
				}
				
				.koko {
					overflow: hidden;
					margin-bottom: 48px;

					.p1 {
						margin-right: 60px;
					}
				}

				.wrap {
					height: 420px;
					padding-right: 120px;
					overflow: auto;
					.el-timeline-item{
						height: auto;
					}
					.el-timeline{
						padding-top:10px ;
					}
					.el-timeline-item__wrapper{
						width: 70%;
						padding-left: 168px;
						top:-12px;
					}
					.el-timeline-item__timestamp{
						
					}
					.el-timeline-item__tail{
						height:100%;
						border-left-style: solid;
						border-left-color: #999999;
						left: 129px;
						// top:22px;
					}
					.el-timeline-item__node--normal{
						left: 120px;
						width: 20px;
						height: 20px;
					}
					.el-timeline-item__content,.el-timeline-item__timestamp{
						text-align: left;
					}
					.el-timeline-item__timestamp.is-bottom{
						margin-top: 5px;
						color: #666 !important;
					}
					.time{
						position: absolute;
						left: 0;
						top:0;
						font-size: 16px;
						
					}
					.date{
							color: #333 !important;
					}
					.specificTime{
						color: #666 !important;
					}
				}
			}


		}
	}
</style>

<style scoped lang="less">
	@media screen and (max-width: 1660px) {
		#particulars-schedule .el-timeline-item .butonezu .el-button {
			transform: scale(0.8) !important;
			margin: 0px !important;
		}

		#particulars-schedule .el-timeline-item .buttwozu .el-button {
			transform: scale(0.8) !important;
			margin: 0px !important;
			width: 140px !important;
			float: left !important;
		}

		#particulars-schedule .el-timeline-item .buttwozu {
			width: 140px !important;
			// bottom: -36% !important;
			right: 2% !important;
		}
	}

	@media screen and (max-width: 1360px) {}
</style>
<style lang="less" scoped>
.el-timeline .el-timeline-item {
	position: relative;
}
.reminder {
	position: absolute;
	top: 0;
	left: 165px;
	width: 260px;
	height: 80px;
	padding: 15px;
	border-radius: 4px;
	box-shadow: 2px 2px 5px #aaa;
	justify-content: space-between;
	box-sizing: border-box;
	background: #fff;
	z-index: 5;
	.top,.bottom {
		font-size: 14px;
		color: #333;
		i {
			font-style: normal;
		}
		span:nth-of-type(1){
			color: #1175d2;
		}
		span:nth-of-type(2){
			margin-right: 26px;
		}
	}
	.bottom {
		margin-top: 10px;
		span:nth-of-type(1){
			margin-right: 13px;
		}
		span:nth-of-type(2){
			margin-right: 34px;
		}
	}
}

.reminderStyle {
	position: absolute;
	top: 3px;
	left: 138px;
	width: 20px;
	height: 20px;
}
</style>
